// --------------------------------------------------------------------------
//   _        _ __   _____  _   _ _____ 
//  | |      / \\ \ / / _ \| | | |_   _|
//  | |     / _ \\ V / | | | | | | | |  
//  | |___ / ___ \| || |_| | |_| | | |  
//  |_____/_/   \_\_| \___/ \___/  |_|  
//                                     
// --------------------------------------------------------------------------


// --------------------------------------------------------------------------
// FULLSCREEN
// --------------------------------------------------------------------------

  @mixin boxFullscreen() {
    width: 100%;
    height: 100vh;
  }


// --------------------------------------------------------------------------
// ABSOLUTE COVER
// --------------------------------------------------------------------------

  @mixin boxCover($cover-padding:0) {
    display: block;
    position: absolute;
    top: 0 + $cover-padding;
    right: 0 + $cover-padding;
    bottom: 0 + $cover-padding;
    left: 0 + $cover-padding;
  }


// --------------------------------------------------------------------------
// POSITIONING
// --------------------------------------------------------------------------

  @mixin boxPosition($top: 0, $left: 0) {
    top: $top;
    left: $left;

    @if $top == 0 and $left > 0 {
      transform:translateX(-50%);
    } @else if $top > 0 and $left == 0 {
      transform:translateY(-50%);
    } 
    
  }


// --------------------------------------------------------------------------
//  CENTERING
// -------------------------------------------------------------------------- 

  @mixin boxCenter($position) {
    position: absolute;
    
    @if $position == 'onY' {
      top: 50%;
      transform: translateY(-50%);
    }
    @else if $position == 'onX' {
      left: 50%;
      transform: translate(-50%);
    }
    @else if $position == 'onXY' {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    } @else {
      @error "`#{$position}` is not a valid value for $position.";

    }
  }

  // usage
  .box--center {
    @include boxCenter(onXY); // centers element on X- and Y-axis
  }


// --------------------------------------------------------------------------
//  ASPECT RATIO
// --------------------------------------------------------------------------   

  // available aspect ratios to be used in mixin
  $mf-aspect-ratios: (
    golden:     (width: 1.61803398875, height: 1),
    square:     (width: 1, height: 1),
    tv:         (width: 4, height: 3),
    widescreen: (width: 5, height: 3),
    hdvideo:    (width: 16, height: 9),
    cinema:     (width: 21, height: 9),
    panavision: (width: 2.76, height: 1),
  ) !default;


  @mixin boxRatio($aspect-ratio: 0, $position...) {

    $ar-height: ();
    $ar-width: ();   

    // if width and height values are passed into mixin
    @if length($aspect-ratio) > 1 {
      $ar-width: nth($aspect-ratio, 1) ;
      $ar-height: nth($aspect-ratio, 2);
    } 

    // if pre-defined aspect ratio from map is passed into mixin 
    @else {
      $ar-width: map-deep-get($mf-aspect-ratios, $aspect-ratio, "width");
      $ar-height: map-deep-get($mf-aspect-ratios, $aspect-ratio, "height");
    }

    /* create basic box layout */

    position: relative;
    width: 100%;

      &:after {
        display: block;
        content: "";
        width: 100%;
        padding-top: ($ar-height / $ar-width) * 100%;
      }

      .l-ratiobox__helper {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }

      /* Position content inside element */
      // One argument: Same offset on X- and Y-axis
      // Two arguments: Separate positioning along X- and Y-axis

      @if length($position) == 1 {

        .l-ratiobox__content {
          position: absolute;
          left: nth($position, 1) * 1%;
          top: nth($position, 1) * 1%;
          transform: translate(nth($position, 1) * -1%, nth($position, 1) * -1%);
          width: 100%;
        }

      } @else if length($position) == 2 {

        .l-ratiobox__content {
          position: absolute;
          left: nth($position, 1) * 1%;
          top: nth($position, 2) * 1%;
          transform: translate(nth($position, 1) * -1%, nth($position, 2) * -1%);
          width: 100%
        }

      } @else if length($position) > 2 {

        // too many arguments
        @error "Two values need to be passed into mixin for positioning the inner element";

      } 

  }

  // usage
  // 
  // .ratio-box--golden {
  //   background: #f00;
  //   @include boxRatio(golden, 50); // golden ratio with X and Y centering
  // } 
  
  // .ratio-box--square {
  //   background: #f00;
  //   @include boxRatio(1 1,); // square with positions on X- and Y-axis
  // } 
  // 
  // .ratio-box--center {
  //   .ratio-box__content {
  //     @include boxCenter(onXY) 
  //   }
  // } 
   


// -------------------------------------------------------------------------- 
//   ____ _____     _______ ____  ____  
//  |  _ \_ _\ \   / / ____|  _ \/ ___| 
//  | | | | | \ \ / /|  _| | |_) \___ \ 
//  | |_| | |  \ V / | |___|  _ < ___) |
//  |____/___|  \_/  |_____|_| \_\____/ 
//
// --------------------------------------------------------------------------                                 


// --------------------------------------------------------------------------
//  TRANSITIONS
// --------------------------------------------------------------------------

  @mixin transition($duration) {
    transition-property: all #{$duration} ease-in-out;
  }

