// --------------------------------------------------------------------------
//     ____ ___  __  __ ____   ___  _   _ _____ _   _ _____ 
//    / ___/ _ \|  \/  |  _ \ / _ \| \ | | ____| \ | |_   _|
//   | |  | | | | |\/| | |_) | | | |  \| |  _| |  \| | | |  
//   | |__| |_| | |  | |  __/| |_| | |\  | |___| |\  | | |  
//    \____\___/|_|  |_|_|    \___/|_| \_|_____|_| \_| |_|  
//                                                                                                                                                                                         
// -------------------------------------------------------------------------- */

  /* Sofortanruf */

  .js-swal-sofortanruf {
  	padding-bottom: 0 !important;
  }

  .js-swal-sofortanruf h2 span {
  	display:block;
  	color: $color-ci-primary;
  	font-weight: normal;
  }
  .js-swal-sofortanruf h2 span + span {
  	display: block;
  	color: $color-ci-secondary-dark;
  	font-size: 20px;
    line-height: 1.2;
  	font-family: $font-primary;
  	font-weight: normal;
  }
  .js-swal-sofortanruf .swal-call {
  	display:inline-block;
  	float:left;
  	width:50%;
  	padding:0 15px;
  	margin-bottom: 20px;
  }
  .js-swal-sofortanruf .swal-call .location {
  	// margin-bottom:10px;
  }
  .js-swal-sofortanruf .sa-icon {
  	margin: 0 auto !important
  }
  .js-swal-sofortanruf .swal-callbutton {
  	display:block;
  	line-height:40px;
  	color: $color-ci-primary;
  	font-size: 24px;
  	font-family: $font-primary;
  }

  @media (max-width: 575px) {
    .js-swal-sofortanruf .swal-callbutton {
      font-size: 16px;
    }
  }

  // .js-swal-sofortanruf .sa-button-container button {
  // 	font-size:12px !important;
  // 	color:#575757 !important;
  // 	background-color:transparent !important;
  // 	padding:0 !important;
  // 	margin:15px 0 0 !important;
  // }
