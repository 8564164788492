/*----------------------------------------------------------------------------
   ____ ___  _     ___  ____  
  / ___/ _ \| |   / _ \|  _ \ 
 | |  | | | | |  | | | | |_) |
 | |__| |_| | |__| |_| |  _ < 
  \____\___/|_____\___/|_| \_\
                                                                                   
  --------------------------------------------------------------------------- */ 

// --------------------------------------------------------------------------
// CONFIG
// --------------------------------------------------------------------------

  $mf-colorscheme: (
    primary: (
      font: $color-ci-primary-contrast,
      paint: $color-ci-primary,
      border: $color-ci-primary-contrast,
    ),
    primary-invers: (
      font: $color-ci-primary,
      paint: $color-ci-primary-contrast,
      border: $color-ci-primary 
    ),
    primary-soft: (
      font: $color-ci-primary-deep,
      paint: $color-ci-primary-soft,
      border: $color-ci-primary-deep,
    ),   
    primary-light: (
      font: $color-white,
      paint: $color-ci-primary-light,
      border: $color-white,
    ), 
    primary-dark: (
      font: $color-white,
      paint: $color-ci-primary-dark,
      border: $color-white,
    ), 
    primary-deep: (
      font: $color-white,
      paint: $color-ci-primary-deep,
      border: $color-white,
    ),             
    primary-gray: (
      font: $color-ci-primary,
      paint: $color-lightgray,
      border: $color-ci-primary,
    ),

    secondary: (
      font: $color-ci-secondary-contrast,
      paint: $color-ci-secondary,
      border: $color-ci-secondary-contrast
    ),
    secondary-invers: (
      font: $color-ci-secondary,
      paint: $color-ci-secondary-contrast,
      border: $color-ci-secondary 
    ),
    secondary-soft: (
      font: $color-ci-secondary-deep,
      paint: $color-ci-secondary-soft,
      border: $color-ci-secondary-deep,
    ),     
    secondary-light: (
      font: $color-white,
      paint: $color-ci-secondary-light,
      border: $color-white,
    ), 
    secondary-dark: (
      font: $color-white,
      paint: $color-ci-secondary-dark,
      border: $color-white,
    ), 
    secondary-deep: (
      font: $color-white,
      paint: $color-ci-secondary-deep,
      border: $color-white,
    ),     
    secondary-gray: (
      font: $color-ci-secondary,
      paint: $color-lightgray,
      border: $color-ci-secondary,
    ),
   
  ) !default;



/*---------------------------------------------------------------------------
  COLOR SCHEMING
  --------------------------------------------------------------------------- */

  @mixin colorscheme($scheme, $mode: false) {

    color: map-deep-get($mf-colorscheme, $scheme, 'font');

    @if $mode == "border" {
      border-color: map-deep-get($mf-colorscheme, $scheme, 'border');
    } @elseif $mode == "backborder" {
      border-color: map-deep-get($mf-colorscheme, $scheme, 'border');
      background-color: map-deep-get($mf-colorscheme, $scheme, 'paint');
    } @else {
      background-color: map-deep-get($mf-colorscheme, $scheme, 'paint');
    }

  } 


  // .testcolor {
  //   @include colorscheme(primary, border);
  // }