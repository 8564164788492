/*---------------------------------------------------------------------------
   _____ ___  _   _ _____ ____  
  |  ___/ _ \| \ | |_   _/ ___| 
  | |_ | | | |  \| | | | \___ \ 
  |  _|| |_| | |\  | | |  ___) |
  |_|   \___/|_| \_| |_| |____/ 
                                                                                                         
  --------------------------------------------------------------------------- */

  // font-face(Font name, file path, font weight [, local name, woff2])

/*---------------------------------------------------------------------------
  EMBED WEBFONTS
  --------------------------------------------------------------------------- */

  @include font-face( 
    'colaborate-thin', 
    'colaborate/colaborate-thin',
    null,
    'colaborate-thin',
    false 
  );

  @include font-face( 
    'colaborate-regular', 
    'colaborate/colaborate-regular',
    null,
    'colaborate-regular', 
    false 
  );

  @include font-face(
    'neuton-regular', 
    'neuton/neuton-regular',
    null,
    'neuton-regular',
    false 
  );


/*---------------------------------------------------------------------------
  ICONFONTS
  --------------------------------------------------------------------------- */

  /* icomoon */ 

  @include font-face(
    'Icomoon', 
    'icomoon/fonts/icomoon',
    null,
    null,
    false 
  );

