/*---------------------------------------------------------------------------
  DEVELOPMENT UTILITIES
  --------------------------------------------------------------------------- */

  .responsive-utilities {

    display: none;

    body.layout--dev & {
      display: block;
    }
    &.active {
      display: block;
    }
    
    position: fixed;
    z-index: 10000;
    bottom: 10px;
    left: 10px;
    width: 30px;
    overflow: hidden;
    white-space: nowrap;

    @include transition(0.3s);

    .responsive-counter {
      display: inline-block;
      margin-right: 5px;
      color: #ffffff;
      font-size: 12px;
      height: 30px;
      width: 30px;
      line-height: 30px;
      background: #bc006e;
      border-radius: 15px;
      cursor: pointer;

      @include transition(0.3s); 
    }

    &.fixed,
    &:hover {
      width: 350px;

      .responsive-counter {
        width: 210px;
        border-radius: 5px;
      }
    }

    .responsive-counter {

      .griddata {
        display: inline-block;
      }

      i {
        display:inline-block;
        height:30px;
        width:30px;
        vertical-align:middle;
        text-align:center;

        &:before {
          font-size:20px;
          line-height:30px;
          margin-right:0;
          color: $color-white;

          @include media(xs) {
            font-size:16px;
            transform:rotate(90deg) translateY(1px);
          }
          @include media(sm) {
            font-size:20px;
            transform:rotate(0deg) translateY(0px);
          }
          @include media(md) {
            font-size:16px;
          }
          @include media(lg) {
            font-size:14px;
          }          
        }
      }
    } 

    .responsive-tool {
      display: inline-block;
      color: #ffffff;
      line-height: 30px;
      height: 30px;
      width: 150px;
      text-align: center;
      vertical-align: middle;

      i:before {
        vertical-align: middle;
        display: inline-block;
        margin-right: 0;
        font-size: 15px;
        line-height: 30px;
        color: $color-white;
      }

      .trigger {
        display:inline-block;
        float:left;
        color:#fff;
        width:30px;
        height:100%;
        line-height:25px;
        cursor:pointer;
        background:#bc006e;
        border-radius:5px;
        margin-right:5px;
      }

    }
  }

  #grid-overlay {
    display:none;
    background:rgba(0,0,0,0);
    pointer-events:none;

    &.active {
      display:block;
      position:fixed;
      top:0;
      left:0;
      bottom:0;
      right:0;
      z-index: 10000;
      width:100%;   

      * {
        height: 100%;
      }
    }  

    [class*="grid-content"] {
      width:100%;
      background:rgba(0,0,0,0.05);
      padding:0;
    }                       

  }
                 
