// --------------------------------------------------------------------------
//     ____ ___  __  __ ____   ___  _   _ _____ _   _ _____ 
//    / ___/ _ \|  \/  |  _ \ / _ \| \ | | ____| \ | |_   _|
//   | |  | | | | |\/| | |_) | | | |  \| |  _| |  \| | | |  
//   | |__| |_| | |  | |  __/| |_| | |\  | |___| |\  | | |  
//    \____\___/|_|  |_|_|    \___/|_| \_|_____|_| \_| |_|  
//                                                                                                                                                                                         
// -------------------------------------------------------------------------- */

/*---------------------------------------------------------------------------
  BASIC BUTTON
  --------------------------------------------------------------------------- */

  .c-button {
    display: inline-block;
    text-decoration: none;
    padding: 0.7em 1em;
    margin-bottom:  $vr-content;
    font-weight: weight(normal);
    cursor:pointer;

    @include transition(0.3s); 
  }

  p.c-button > a {
    display: inline-block;
    padding: 0.7em 1em;
    font-weight: weight(normal);
    text-decoration: none;
    cursor:pointer;

    @include transition(0.3s);
  }


/*---------------------------------------------------------------------------
  BUTTON STYLES
  --------------------------------------------------------------------------- */

  .c-button--block {
    display: inline-block;

    /* primary color scheme */

    &.c-button--primary {
      @include colorscheme(primary);

      &:hover {
        @include colorscheme(secondary);
      }       
    }    

    .bg-primary &.c-button--primary {
      @include colorscheme(secondary);

      &:hover {
        @include colorscheme(secondary-dark);
      }       
    } 


     /* secondary color scheme */

    &.c-button--secondary {
      @include colorscheme(secondary);

      &:hover {
        @include colorscheme(primary);
      }       
    }    
  }


  .c-button--outlined {
    display: inline-block;
    border-width: 1px;
    border-style: solid;

    /* primary color scheme */

    &.c-button--primary {
      @include colorscheme(primary-invers, border);

      &:hover {
        @include colorscheme(primary);
      }       
    }    

    .bg-primary &.c-button--primary {   
      border-color: $color-white;
      color: $color-white;

      // &:hover {
      //   @include colorscheme(secondary-dark);
      // }       
    } 

     /* secondary color scheme */
    &.c-button--secondary {
      @include colorscheme(secondary-invers, border);

      &:hover {
        @include colorscheme(secondary);
      }       
    }  
  }





  // .c-button--block {

  //   &.c-button--white  {
  //     background-color: $color-white;
  //   }
  //   &.c-button--primary {
  //     background-color: $color-ci-primary;
  //     color: $color-white;

  //     &:hover {
  //       background-color: $color-gray;
  //       color: $color-white;        
  //     }  
  //   }

  //   .bg-primary &.c-button--primary {
  //     background-color: $color-white;
  //     color: $color-ci-primary;

  //     &:hover {
  //       background-color: darken($color-ci-primary, 10%);
  //       color: $color-white;        
  //     }  
  //   } 

  // }


  // .c-button--outlined {

  //   > a {
  //     border-width: 1px;
  //     border-style: solid;
  //   }

  //   &.c-button--white > a {
  //     border-color: $color-white;
  //     color: $color-white;
  //   }
    
  //   &.c-button--primary > a {
  //     border-color: $color-ci-primary;
  //     color: $color-ci-primary;

  //     &:hover {
  //       border-color: $color-gray;
  //       color: $color-gray;        
  //     }      
  //   }

  // }
