// --------------------------------------------------------------------------
//     ____ ___  __  __ ____   ___  _   _ _____ _   _ _____ 
//    / ___/ _ \|  \/  |  _ \ / _ \| \ | | ____| \ | |_   _|
//   | |  | | | | |\/| | |_) | | | |  \| |  _| |  \| | | |  
//   | |__| |_| | |  | |  __/| |_| | |\  | |___| |\  | | |  
//    \____\___/|_|  |_|_|    \___/|_| \_|_____|_| \_| |_|  
//                                                                                                                                                                                         
// -------------------------------------------------------------------------- */


/*---------------------------------------------------------------------------
  BASIC SETUP
  --------------------------------------------------------------------------- */

  .c-owl {

    // owl navigation 
    .owl-nav {
      text-align: center;
      -webkit-tap-highlight-color: transparent; 

      button {
        display: inline-block;

        &:before {
          @include transition(.3);
        }
        &.owl-prev:before {
          content: '\e93b';
        } 
        &.owl-next:before {
          content: '\e93c';
        }   
      }
    }

    // owl dots
    .owl-dots {
      width: 100%;
      margin: 0 auto;
      text-align: center;
      -webkit-tap-highlight-color: transparent; 

      .owl-dot {
        display: inline-block;
        zoom: 1;
        *display: inline; 

        span {
          width: 10px;
          height: 10px;
          margin: 5px 7px;
          background: lighten($color-ci-secondary, 50%);
          display: block;
          -webkit-backface-visibility: visible;
          transition: opacity 200ms ease;
          border-radius: 30px; 
        }

        &.active span, 
        &:hover span {
          background: $color-ci-primary-dark; 
        }      
        .bg-primary &.active span,
        .bg-primary &:hover span {
          background: $color-ci-primary-dark;
        }
        .bg-secondary &.active span,
        .bg-secondary &:hover span {
          background: $color-ci-secondary-dark;
        } 

      }
    } 

  }


/*---------------------------------------------------------------------------
  OWL THEME: PANEL IMAGE SLIDER
  --------------------------------------------------------------------------- */

  .c-owl-theme--panel {

    .owl-nav {
      width: 100%;

      button {
        position: absolute;
        top: 0;
        width: 10%;
        height: calc(100% - 30px);

        &.owl-prev {
          left: 0;
        } 
        &.owl-next {
          right: 0;
        } 

        &:before {
          color: $color-white;
          opacity: 0.3;

          @include fluid-type(300px, 1800px, 24px, 80px);
        }
        &:hover:before {
          opacity: 1;
        }   
        &.disabled:before {
          opacity: 0;
        }   
      }
    }

    .owl-dots {
      position: relative;
      margin-top: 15px;
      height: 30px;
    } 

    & + .l-panel .l-panel__inside {
      padding-top: $vr-content;
    } 

  }


/*---------------------------------------------------------------------------
  OWL THEME: GALLERY SLIDER
  --------------------------------------------------------------------------- */

  .s-owl-theme--gallery {
    padding-bottom: 50px;
    
    .owl-nav {
      position: absolute;
      z-index: 2;
      display: inline-block;

      button {
        margin-top: 1rem;
        text-align: center;
        width: 40px;

        &:before {
          color: $color-ci-primary;
          font-size: 1.5rem;
        }
    
      }
    }

    .owl-dots {
      position: absolute;
      height: 30px;
      bottom: -50px;
    } 
  }


/*---------------------------------------------------------------------------
  OWL THEME: CONTENT SLIDER
  --------------------------------------------------------------------------- */

  .s-owl-theme--content {
    margin: 2rem auto 0;
    padding-bottom: 30px;

    // create space for owl nav arrows 
    width:  calc(100% - 140px);

    .owl-nav {
      display: block;
      width: 100%;

      &.disabled {
        display: block;
        opacity: 0.3;
      }          


      button {
        position: absolute;
        top: 0;
        width: 50px;
        height: 100%;

        &.owl-prev {
          left: -70px;
        } 
        &.owl-next {
          right: -70px;
        } 

        &:before {
          color: $color-ci-primary;
          font-size: 1.5rem;
        }
        .bg-primary &:before {
          color: $color-white;
        }
        .bg-secondary &:before {
          color: $color-white;
        }        
      }
    }

    // remove nav arrows and maximize slider width on small screens 
    @include media(-xs) {
      width: 100%;

      .owl-nav {
        display: none;
      }
    }

    .owl-dots {
      position: relative;
      height: 30px;
      bottom: -20px;
    } 
  }

