/*----------------------------------------------------------------------------

  _   _ _____    _    ____  _____ ____  
 | | | | ____|  / \  |  _ \| ____|  _ \ 
 | |_| |  _|   / _ \ | | | |  _| | |_) |
 |  _  | |___ / ___ \| |_| | |___|  _ < 
 |_| |_|_____/_/   \_\____/|_____|_| \_\
                                        
                                                                             
  --------------------------------------------------------------------------- */                                                                            

  .l-page__header {
    position: fixed;
    z-index: 10;
    top: 0;
    display: block;
    width: 100%;
    min-height: 60px;
    background: $color-white;
    box-shadow: 0 0 0 0 #eee, 0 3px 2px 2px rgba(0, 0, 0, 0.05);   

    @include media(md) {
      height: 80px;
    }
    @include media(xl) {
      height: 95px;
    }    
  }  

  .l-page__header + .l-page__container,
  .l-page__header + .custom {
    padding-top: 60px;

    @include media(md) {
      padding-top: 80px  
    }    
    @include media(xl) {
      padding-top: 95px  
    }       
  }



/*---------------------------------------------------------------------------
  HEADER LOGO
  --------------------------------------------------------------------------- */

  .logo-primary {
    position: absolute;
    z-index: 100;
    display: inline-block;
    width: 130px;
    height: 60px;
    margin-bottom: 0;
    margin-right: 20px;
    float: left;
    overflow: hidden;

    @include transition(0.3s);


    // align logo to upper left page corner on smaller screens
    @include media(-sm) {
      left: 0;
    }   

    // increase logo size on larger screens
    @include media(sm) {
      height: 130px;

      .l-page__header.js-active &,
      &.js-collapsed,
      body.logo-collapsed & {
        height: 60px;
      }
    }

    @include media(md) {
      position: relative;
      width: 170px;
      height: 170px;
      margin-right: 3%;

      .l-page__header.js-active &,
      &.js-collapsed,
      body.logo-collapsed & {
        height: 80px;
      }      
    }    

    @include media(xl) {
      width: 200px;
      height: 200px;

      .l-page__header.js-active &,
      &.js-collapsed,
      body.logo-collapsed & {
        height: 95px;
      }        
    } 

  }  


/*---------------------------------------------------------------------------
  HEADER ITEMS
  --------------------------------------------------------------------------- */

  .c-header__items {
    padding-left: 130px;
    height: 60px;

    @include media(sm) {
      padding-left: 150px;
    }

    @include media(md) {
      height: auto;
    }
  }
  

  .c-header__info {
    display: inline-block;
    position: relative;
    padding-right: 30px;

    p {
      margin-bottom: 0;
    }

    /* hide element on small screens */
    @include media(-xs) {
      display: none;
    }
  }

  .c-header__icons.c-iconlinks {
    margin: 0;

    .c-iconlink--number {
      margin-right: 1em;

      &:before {
        margin-right: 0.5em;
      }
    }
    .c-iconlink__link {
      margin-bottom: 0;
    }
    .icon-envelope:before {
      line-height: 1.8em;
    }
    .icon-menu:before {
      line-height: 2em;
    }   
  }

  /* layout when navbar is not collapsible */ 

  @include media(-md) {

    .c-header__items {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    .c-header__info {
      flex: 1 1 auto;
    }
    .c-header__icons.c-iconlinks {
      flex: 0 0 auto;
    }    
  }   

  /* layout when navbar is collapsible */ 

  @include media(md) {

    .c-header__items {
      p {
        margin: 12px 0 10px;
        font-size: 0.9em;
      }      
    }   

    .c-header__icons.c-iconlinks {
      float: right;
      line-height: 80px;
    }   
  }

  @include media(xl) {

    .c-header__items {
      p {
        margin: 15px 0 15px;
        font-size: 1em;
      }      
    }   

    .c-header__icons.c-iconlinks {
      line-height: 95px;
    }   
  }