 /*----------------------------------------------------------------------------
  _____ ___  ____  __  __ ____  
 |  ___/ _ \|  _ \|  \/  / ___| 
 | |_ | | | | |_) | |\/| \___ \ 
 |  _|| |_| |  _ <| |  | |___) |
 |_|   \___/|_| \_\_|  |_|____/ 
                                
  --------------------------------------------------------------------------- */ 

/*---------------------------------------------------------------------------
  FORM LAYOUT
  --------------------------------------------------------------------------- */

	/* vertical layout */

	.bs-form__group {
		margin-bottom: 1rem;

		.l-box .row &:last-child {
			margin-bottom: 0;
		}
	} 
 


	.form__check-legend,
	.form__radio-legend  {
		margin-bottom: 0.5rem;
	}

	.widget-explanation {
		margin-bottom: 2rem;
	}

	/* horizontal form layout */

	.l-form--horizontal.bs-form__group,
	.l-form--horizontal .bs-form__group {
		display: flex;

		// make file upload display vertically on small devices
		@include media(-xs) {
			&.widget-upload {
				display: block;
			}	
		}
	}

	.l-form--horizontal .bs-form__label {
		flex: 0 0 135px;
	}

	/* inline checkboxes / radiobuttons */

	.l-form__check--inline .bs-form__check,
	.l-form__radio--inline .bs-form__check  {
	  display: inline-flex;
	  margin-right: 1rem;
	}

	/* inline form layout */

	.l-form-inline {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		grid-column: auto;
	}

	.l-form-inline .bs-form__label {
		flex: 0 0 auto;
		margin-right: 1rem;
	}
	
	.l-form-inline .bs-form__group {
		flex: 0 0 auto;
    width: auto;
    max-width: 100%;		
		display: flex;
		align-items: center;
    margin-right: 1rem;
	}

	/* show cursor on hovering checkboxes */

	.form-check-input:hover,
	.form-check-label:hover {cursor: pointer}



/*---------------------------------------------------------------------------
  FORM THEME
  --------------------------------------------------------------------------- */

	.formbody button.c-button {
		padding: 0.375rem 1rem;
		line-height:1.5;
	}
