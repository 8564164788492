// --------------------------------------------------------------------------
//     ____ ___  __  __ ____   ___  _   _ _____ _   _ _____ 
//    / ___/ _ \|  \/  |  _ \ / _ \| \ | | ____| \ | |_   _|
//   | |  | | | | |\/| | |_) | | | |  \| |  _| |  \| | | |  
//   | |__| |_| | |  | |  __/| |_| | |\  | |___| |\  | | |  
//    \____\___/|_|  |_|_|    \___/|_| \_|_____|_| \_| |_|  
//                                                                                                                                                                                         
// -------------------------------------------------------------------------- */

/*---------------------------------------------------------------------------
  CALLOUT PANELS
  --------------------------------------------------------------------------- */

 .callout {text-align: center;max-width:700px;margin: 0 auto;padding-bottom: 30px;}



	.c-panel-callout {
		background: $color-ci-primary;
	}

	.c-callout-panel__headline {
	 	color: $color-white;
	 	text-align: center;

	 	@include fluid-type(300px, 1440px, 16px, 36px); 

	}

