
 /*---------------------------------------------------------------------------
    ____  _____ ____ ___ ____ _   _ 
   |  _ \| ____/ ___|_ _/ ___| \ | |
   | | | |  _| \___ \| | |  _|  \| |
   | |_| | |___ ___) | | |_| | |\  |
   |____/|_____|____/___\____|_| \_|                                    
                                                                                                                                                                           
  --------------------------------------------------------------------------- */

// --------------------------------------------------------------------------
// BASIC COLORS
// --------------------------------------------------------------------------

  /* font colors */
  
  .color-white {
    color: $color-white;
  }    
  .color-black {
    color:$color-black;
  }   

  /* background colors */

  .bg-white {
    background-color: $color-white;
  }  
  .bg-black {
    background-color:$color-black;
  }
  .bg-gray {
    background-color:$color-gray;
  } 
  .bg-lightgray {
    background-color:$color-lightgray;
  }     
  .bg-darkgray {
    background:$color-darkgray;
  }
  .bg-black {
    background:$color-black;
  }    


// --------------------------------------------------------------------------
// CI COLORS
// --------------------------------------------------------------------------    

  /* primary colors */

  .bg-primary {
    background:$color-ci-primary;
    p, h1, h2, h3, h4, h5, h6, li, span {
        color: $color-white;
    }
  }    

  .bg-primary-light {
    background:$color-ci-primary-light;
    color: $color-white;
  }   
  .bg-primary-soft {
    background:$color-ci-primary-soft;
    color: $color-ci-primary-deep;
  }  
  .bg-primary-dark {
    background:$color-ci-primary-dark;
    color: $color-white;
  }  
  .bg-primary-deep {
    background:$color-ci-primary-deep;
    color: $color-white;
  }  

  /* secondary colors */

  .bg-secondary {
    background:$color-ci-secondary; 
    p, h1, h2, h3, h4, h5, h6, li {
        color: $color-white;
    }
  }  

  .bg-secondary-light {
    background:$color-ci-secondary-light;
    color: $color-ci-primary;
  }   
  .bg-secondary-soft {
    background:$color-ci-secondary-soft;
    color: $color-ci-secondary-dark;
  }  
  .bg-secondary-dark {
    background:$color-ci-secondary-dark;
    color: $color-white;
  }  
  .bg-secondary-deep {
    background:$color-ci-secondary-deep;
    color: $color-white;
  }  


// --------------------------------------------------------------------------
// COLOR SCHEMES
// --------------------------------------------------------------------------  

  /* primary colors */

  .colors-primary {
    @include colorscheme(primary);
  }
  .colors-primary-invers {
    border-width: 1px;
    border-style: solid;
    @include colorscheme(primary-invers, backborder);
  }

  .colors-primary-soft {
    @include colorscheme(primary-soft);
  }
  .colors-primary-soft-framed {
    border-width: 1px;
    border-style: solid;
    @include colorscheme(primary-soft, backborder);
  }
  .colors-primary-light {
    @include colorscheme(primary-light);
  }  
  .colors-primary-dark {
    @include colorscheme(primary-dark);
  }   
  .colors-primary-deep {
    @include colorscheme(primary-deep);
  }     

  .colors-primary-gray {
    @include colorscheme(primary-gray);
  }

  /* secondary colors */

  .colors-secondary {
    @include colorscheme(secondary)
  }
  .colors-secondary-invers {
    border-width: 1px;
    border-style: solid;
    @include colorscheme(secondary-invers, backborder)
  }  

  .colors-secondary-soft {
    @include colorscheme(secondary-soft);
  }
  .colors-secondary-soft-framed {
    border-width: 1px;
    border-style: solid;
    @include colorscheme(secondary-soft, backborder);
  }    
  .colors-secondary-light {
    @include colorscheme(secondary-light);
  }  
  .colors-secondary-dark {
    @include colorscheme(secondary-dark);
  }   
  .colors-secondary-deep {
    @include colorscheme(secondary-deep);
  }     

  .colors-secondary-gray {
    @include colorscheme(secondary-gray);
  }

