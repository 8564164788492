/*---------------------------------------------------------------------------
	  _     ___ ____ _____ ____  
	 | |   |_ _/ ___|_   _/ ___| 
	 | |    | |\___ \ | | \___ \ 
	 | |___ | | ___) || |  ___) |
	 |_____|___|____/ |_| |____/ 
	                                                                                     
  --------------------------------------------------------------------------- */

/*---------------------------------------------------------------------------
  UNORDERED LIST
  --------------------------------------------------------------------------- */

	/* default ordered list */ 
	
  .ce_text > ul {
    list-style: none;
    padding-left: 2em;

    li {
      position: relative;

      &:before {
        position: absolute;
        left: -1.5em;
        display: inline-block;
        text-align: right;
      }
    }

		ul {
			padding-left: 2em;
		}    

  }

  .ce_text ul li:before {
    content: '-'; 
  }


/*---------------------------------------------------------------------------
  ORDERED LIST
  --------------------------------------------------------------------------- */

	/* default ordered list */ 

  .ce_text > ol {
    list-style: none;
    counter-reset: list-counter;
    padding-left: 2.5em;

    li {
      position: relative;
    }

    & > li {
      counter-increment: list-counter;

      &:before {
      	width: 2em;
        position: absolute;
        left: -3em;
        display: inline-block;
        text-align: right;
        content: counter(list-counter) '.';
      }
    }

		ol {
			padding-left: 2em;

			li {
				list-style-type: lower-alpha;
				padding-left: 1em;
	    } 

			li li {
				list-style-type: lower-roman;
	    } 	    

			li li li {
				list-style-type: lower-roman;
	    } 	 
		}
  }

	
	/* styled ordered list */

  // .ce_text ol {
  //   list-style: none;
  //   counter-reset: list-counter;
  //   padding-left: 3rem;

  //   li {
  //     position: relative;
  //     margin: 0 0 0.5rem 0;
  //     counter-increment: list-counter;
  //     margin-bottom: 1rem;

  //     &:before {
  //       position: absolute;
  //       left: -3.5em;
  //       top: -0.3em;
  //       content: counter(list-counter) '';
  //       display: inline-block;
  //       background: $color-ci-primary;
  //       width: 2rem;
  //       height: 2rem;
  //       border-radius: 50%;
  //       margin-right: 1rem;
  //       // font-size: 0.8em;
  //       text-align: center;
  //       // text-align: right;
  //       line-height: 2rem;
  //       color: $color-white;
  //     }
  //   }

  // }


/*---------------------------------------------------------------------------
  ICON LISTS
  --------------------------------------------------------------------------- */

	.c-iconlist__list {
		list-style-type: none;
		// font-size: 1rem; // scale whole component
	}

	.c-iconlist__item {
		position: relative;
	  display: flex; 

		&:before {
			position: absolute;
			color: $color-ci-primary;
		}

		&.c-iconlist__item--check:before {
			content: '\e935';
		}
		&.c-iconlist__item--dash:before {
			content: '\e937';
		}
		&.c-iconlist__item--question:before {
			content: '\e933';
		}
		&.c-iconlist__item--chevron:before {
			content: '\e93c';
		}
		&.c-iconlist__item--star:before {
			content: '\e68d';
		}				
	}

	.ce_text .c-iconlist__list,
	.ce_text .c-iconlist__list--small {
		padding-left: 2.5em;

		.c-iconlist__item {
	  	align-items: start;
		}

		.c-iconlist__item:before {
			left: -2em;
			top: .3em;
			font-size: 1em;
		}
	}

	.ce_text .c-iconlist__list--large {
		padding-left: 3.6em;

		.c-iconlist__item {
	  	align-items: center;
		}

		.c-iconlist__item:before {
			left: -1.8em;
			font-size: 1.8em;
		}
	}