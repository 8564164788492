// --------------------------------------------------------------------------
//     ____ ___  __  __ ____   ___  _   _ _____ _   _ _____ 
//    / ___/ _ \|  \/  |  _ \ / _ \| \ | | ____| \ | |_   _|
//   | |  | | | | |\/| | |_) | | | |  \| |  _| |  \| | | |  
//   | |__| |_| | |  | |  __/| |_| | |\  | |___| |\  | | |  
//    \____\___/|_|  |_|_|    \___/|_| \_|_____|_| \_| |_|  
//                                                                                                                                                                                         
// -------------------------------------------------------------------------- */

/*---------------------------------------------------------------------------
  RATIO ITEMS
  --------------------------------------------------------------------------- */

  .l-ratiobox--golden {
    background: #f00;
    @include boxRatio(golden, 50); // golden ratio with X and Y centering
  } 

  .l-ratiobox--square {
    background: #f00;
    @include boxRatio(golden, 50); // golden ratio with X and Y centering
  }   

  .l-ratiobox--widescreen{
    background: #f00;
    @include boxRatio(golden, 50); // golden ratio with X and Y centering
  }   