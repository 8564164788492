/*----------------------------------------------------------------------------
   ____ ____  ___ ____  
  / ___|  _ \|_ _|  _ \ 
 | |  _| |_) || || | | |
 | |_| |  _ < | || |_| |
  \____|_| \_\___|____/ 
                                                                             
  --------------------------------------------------------------------------- */ 

// --------------------------------------------------------------------------
// CONFIG
// --------------------------------------------------------------------------

  // Grid columns 
  $mf_grid-cols: 12 !default;
  $mf_grid-cols-pctg: 100% / 12 !default;

  // Grid gutter
  $mf_gutter-default: 2rem !default;
  $mf_gutter-small:   1rem !default;
  $mf_gutter-medium:  $mf_gutter-default !default;
  $mf_gutter-large:   4rem !default;


/*---------------------------------------------------------------------------
  FLUID CONTAINER
  --------------------------------------------------------------------------- */

  // define breakpoint at which container has fixed width  
  $mf_fluid-container-break: 'md' !default;

  // page container ist fluid by default
  $mf_static-container: false; 

  // container width as a percentage of breakpoint width
  $mf_container-width: 0.95; 

  @each $bpName, $bpValue in $mf_breakpoints {

    // check if container layout is still fluid
    @if $bpName == $mf_fluid-container-break {
      $mf_static-container: true;
    }

    // limit container width indivually for each breakpoint
    @if $mf_static-container {
      @include media('#{$bpName}') {
        .l-page__inside,
        .l-panel__inside {
          max-width: $bpValue * $mf_container-width;
        }
      }

    }

  }


/*---------------------------------------------------------------------------
  GRID ROWS
  --------------------------------------------------------------------------- */

  .row {
    display: grid;
    grid-template-columns: repeat($mf_grid-cols, minmax(0, 1fr));
    grid-auto-columns: 1fr;
    grid-column-gap: $mf_gutter-default;
    grid-template-rows: auto;
    grid-auto-rows: auto;
    grid-row-gap: 0;
  } 


/*---------------------------------------------------------------------------
  GRID AND UTILITIES
  --------------------------------------------------------------------------- */

  // create grid column and row classes for each breakpoint

  @mixin createGridClasses($bpName, $i) {

    $bpName: if($bpName, '-#{$bpName}', '');

    // spans
    .col#{$bpName}-#{$i} {
      grid-column: span #{$i} / span #{$i};
    }
    .row-span#{$bpName}-#{$i} {
      grid-row: span #{$i} / span #{$i};
    }

  }

  // create position, alignment and gutter classes for each breakpoint

  @mixin createGridUtilities($bpName, $i) {

    $bpName: if($bpName, '-#{$bpName}', '');

    // position
    .col-start#{$bpName}-#{$i} {
      grid-column-start: #{$i};
    }
    .row-start#{$bpName}-#{$i} {
      grid-row-start: #{$i};
    }

    // on last run create utilities
    @if $i == $mf_grid-cols {

      // vertical alignment
      .align#{$bpName}-center {
        align-self: center;
      }
      .align#{$bpName}-start {
        align-self: start;
      }
      .align#{$bpName}-end {
        align-self: end;
      }
      .align#{$bpName}-stretch {
        align-self: stretch;
      }    

      // horizontal alignment
      .justify#{$bpName}-center {
        justify-self: center;
      }
      .justify#{$bpName}-start {
        justify-self: start;
      }
      .justify#{$bpName}-end {
        justify-self: end;
      }
      .justify#{$bpName}-stretch {
        justify-self: stretch;
      }  
      .pull-right#{$bpName} {
        grid-column-end: -1;
      }
      .pull-left#{$bpName} {
        grid-row-start: 1;
        grid-column-end: inherit;
      }

      // gutter
      .row.l-gutter#{$bpName}-small,
      .l-gutter#{$bpName}-small .l-panel__row,
      .l-gutter#{$bpName}-small .l-content__row {
        grid-column-gap: 1rem; 
      }
      .row.l-gutter#{$bpName}-medium,
      .l-gutter#{$bpName}-medium .l-panel__row,
      .l-gutter#{$bpName}-medium .l-content__row {
        grid-column-gap: 2rem;
      }
      .row.l-gutter#{$bpName}-large,
      .l-gutter#{$bpName}-large .l-panel__row,
      .l-gutter#{$bpName}-large .l-content__row {
        grid-column-gap: 4rem;
      }

      // vertical collpase
      .row.l-collapse#{$bpName} {
        grid-auto-rows: 1fr;
      }
      .row.l-collapse#{$bpName}-none {
        grid-auto-rows: auto;
      }
    }
  }


  // create grid and utility classes below first breakpoint
  
  @for $i from 1 through $mf_grid-cols {
    @include createGridClasses(false, $i);
  }

  @for $i from 1 through $mf_grid-cols {
    @include createGridUtilities(false, $i);
  }

  // create grid and utility classes for each breakpoint

  @each $bpName, $bpValue in $mf_breakpoints {

    @include media('#{$bpName}') {
 
      // grid column classes first
      @for $i from 1 through $mf_grid-cols {
        @include createGridClasses($bpName, $i);
      }

      // grid position classes last 
      // col-start-md-4 needs to overwrite col-md-6
      @for $i from 1 through $mf_grid-cols {
        @include createGridUtilities($bpName, $i);
      }
    }  
  }


/*---------------------------------------------------------------------------
  FLUID GUTTER
  Adapted from: https://css-tricks.com/snippets/css/fluid-typography/
  --------------------------------------------------------------------------- */

  $mf_min-gutter: 16px !default;
  $mf_max-gutter: 90px !default;

  $mf_min-gutter-small: 10px !default;
  $mf_max-gutter-small: 32px !default;

  $mf_min-gutter-large: 20px !default;
  $mf_max-gutter-large: 120px !default;

  // stop fluid gutter at last breakpoint from breakpoint map
  $mf_gutter-max-vw: map-get($mf_breakpoints, nth(map-keys($mf_breakpoints), length(map-keys($mf_breakpoints))));

  // set min container width for fluid gutter when gutter is too large
  $mf_gutter-min-vw: 768px !default;

  @mixin createFluidGutter($min-vw, $max-vw, $min-gutter-size, $max-gutter-size) {

    & {

      @if $min-vw == null {

        grid-column-gap: calc(#{$min-gutter-size} + #{strip-unit($max-gutter-size - $min-gutter-size)} * ((100vw - 320px) / #{strip-unit($max-vw - 320px)}));
      
      } @else {

        grid-column-gap: $min-gutter-size;

        @media screen and (min-width: $min-vw) {
          grid-column-gap: calc(#{$min-gutter-size} + #{strip-unit($max-gutter-size - $min-gutter-size)} * ((100vw - 320px) / #{strip-unit($max-vw - 320px)}));
        }

      }

      @media screen and (min-width: $max-vw) {
        grid-column-gap: $max-gutter-size;
      }

    }

  }

  .l-gutter-fluid,
  .l-gutter-fluid .row,
  .l-gutter-fluid .row .row {
    grid-column-gap: $mf_gutter-default; // browser fallback
    @include createFluidGutter(null, $mf_gutter-max-vw, $mf_min-gutter, $mf_max-gutter);
  }

  // custom gutter
  .l-gutter-fluid.l-gutter-small {
    grid-column-gap: $mf_gutter-small; // browser fallback
    @include createFluidGutter(null, $mf_gutter-max-vw, $mf_min-gutter-small, $mf_max-gutter-small);
  }   
  .l-gutter-fluid.l-gutter-large {
    grid-column-gap: $mf_gutter-default; // browser fallback
    @include createFluidGutter($mf_gutter-min-vw, $mf_gutter-max-vw, $mf_min-gutter-large, $mf_max-gutter-large);
  }   



/*---------------------------------------------------------------------------
  STANDARDIZED GRID
  --------------------------------------------------------------------------- */

  .l-grid {
    display: grid;
    grid-auto-columns: 1fr;
    grid-row-gap: 0;
    
    // remove default image margins because of grid-row-gap
    .ce_gallery & {
      figure {
        margin-bottom: 0;
      }
    }
  } 

  $lastBpValue: map-get($mf_breakpoints, nth(map-keys($mf_breakpoints), length($mf_breakpoints)));
  $maxContainerSize: $lastBpValue * $mf_container-width;
  $netContainerSize: $maxContainerSize - 60;

  @for $i from 1 through $mf_grid-cols {

    $gridGapDefault: 0.5rem + (12rem - $i) / 10;
    $gridGapSmall: 0.5rem + (12rem - $i) / 25;
    $gridGapLarge: 0.5rem + (12rem - $i) / 5;

    .l-cols--#{$i} {
      grid-template-columns: repeat(auto-fit, minmax(($netContainerSize - strip-unit($gridGapDefault)*$max_font*($mf_grid-cols - 1)) / #{$i}, 1fr)  );
      grid-gap: $gridGapDefault;    

      .l-gutter-small &.l-grid {
        grid-template-columns: repeat(auto-fit, minmax(($netContainerSize - strip-unit($gridGapSmall)*$max_font*($mf_grid-cols - 1)) / #{$i}, 1fr)  );
        grid-gap: $gridGapSmall;
      }
      .l-gutter-large &.l-grid {
        grid-template-columns: repeat(auto-fit, minmax(($netContainerSize - strip-unit($gridGapLarge)*$max_font*($mf_grid-cols - 1)) / #{$i}, 1fr)  );
        grid-gap: $gridGapLarge;
      }   
    }
  }


