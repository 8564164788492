/*---------------------------------------------------------------------------
  
  PAGE FOOTER
  Layout, Logo, Navigation, etc.

  --------------------------------------------------------------------------- */

  .l-page__footer {
    background: $color-ci-primary;
    padding:25px 0;

    li {
			display: inline-block;
			float: right;
			margin-left: 30px;

  		a, span, strong {
  			color: $color-white;

  		}
    }

  }  

