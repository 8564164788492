/*---------------------------------------------------------------------------
   ____    _    ____  _____ 
  | __ )  / \  / ___|| ____|
  |  _ \ / _ \ \___ \|  _|  
  | |_) / ___ \ ___) | |___ 
  |____/_/   \_\____/|_____|
                                                                                          
  --------------------------------------------------------------------------- */


/*---------------------------------------------------------------------------
  BOX SIZING
  https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
  --------------------------------------------------------------------------- */

  html {
    box-sizing:border-box;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }


/*---------------------------------------------------------------------------
  HTML5 BOILERPLATE
  https://github.com/h5bp/html5-boilerplate/blob/master/dist/css/main.css
  --------------------------------------------------------------------------- */

  /* forms */ 

  fieldset {
    border: 0;
    margin: 0;
    padding: 0;
  }

  textarea {
    resize: vertical;
  }

  /* text selection */

  ::-moz-selection {
    background: #b3d4fc;
    text-shadow: none;
  }

  ::selection {
    background: #b3d4fc;
    text-shadow: none;
  }

  /* hint for outdated IE browsers */

  .browserupgrade {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
  }


/*---------------------------------------------------------------------------
  IMAGES
  --------------------------------------------------------------------------- */

  img {
    line-height:0;
    vertical-align:middle;
    border:0;
    -ms-interpolation-mode:bicubic;
  } 

  img {
    max-width:100%; // do not scale up larger than original size
    width: 100%;
    height:auto;
  }

  figure {
    margin: 0;

    @include media(xs) {
      &.float_left,
      &.float_right {
        margin-bottom: 1em;

        img {
          width: auto;
        } 
      }

      &.float_left  { 
        float: left;
        padding-right: $vr-content;
      }
      &.float_right  { 
        float: right;
        padding-left: $vr-content;
      }
    }      
  }

  video {
    display: block;
    width: 100%;  
    height: auto !important;
  }

  
/*---------------------------------------------------------------------------
  LISTS
  --------------------------------------------------------------------------- */

  /* Remove default list style */

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  } 

  
/*---------------------------------------------------------------------------
  DIVERS
  --------------------------------------------------------------------------- */
  
  iframe {
    display: block;
    width: 100%;
    height: auto; 
    border:none;
  }
  

/*---------------------------------------------------------------------------
  LAZYLOADING
  --------------------------------------------------------------------------- */

  .lazyload, .lazyloading {
    opacity: 0; 
  }
  .lazyloaded {
    opacity: 1; 
    transition: opacity 300ms;
  }

