/*---------------------------------------------------------------------------
  _   _ _____ ___ _     ___ _____ ___ _____ ____  
 | | | |_   _|_ _| |   |_ _|_   _|_ _| ____/ ___| 
 | | | | | |  | || |    | |  | |  | ||  _| \___ \ 
 | |_| | | |  | || |___ | |  | |  | || |___ ___) |
  \___/  |_| |___|_____|___| |_| |___|_____|____/ 
                                                  
  --------------------------------------------------------------------------- */


/*---------------------------------------------------------------------------
  VISIBILITY
  @see https://getbootstrap.com/docs/4.4/getting-started/accessibility/
  @see https://necolas.github.io/normalize.css/
  --------------------------------------------------------------------------- */

  /* Contao: Hide elements while making it readable for screen readers */

  .invisible {
    display: inline;
    height: 0;
    left: -1000px;
    overflow: hidden;
    position: absolute;
    top: -1000px;
    width: 0;
  }

  /* Bootstrap: Hide elements while making it readable for screen readers */  

  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0 0 0 0);
    border:0;
  }

  /**
   * Hide text while making it readable for screen readers
   * 1. Needed in WebKit-based browsers because of an implementation bug;
   *    See: https://code.google.com/p/chromium/issues/detail?id=457146
   */
  .hide-text {
    overflow: hidden;
    padding: 0; /* 1 */
    text-indent: 101%;
    white-space: nowrap;
  }

  /* device-based visibility */

  html.js-phone .u-hide-fon,
  html.js-no-phone .u-hide-no-fon {display: none;}  

  /* mediaquery-based visibility */

  @include media(-xs) {
    .u-hide-fon {display: none !important;}  
  }
  @include media(md) {
    .u-hide-no-fon {display: none !important;}  
  }


/*---------------------------------------------------------------------------
  CLEARING & CLEARFIXING 
  --------------------------------------------------------------------------- */

  .u-clearfix {
    @include clearfix(); 
  }

  .u-clear {
    clear: both;

    &:before {
      content: '';
      clear: both;
      display: table;

    }
  }
  .u-clear-left {
    clear: left;
  }
  .u-clear-right {
    clear: right;
  }

/*---------------------------------------------------------------------------
  POSITIONING 
  --------------------------------------------------------------------------- */

  .u-float-left {
    float: left;
  }
  .u-float-right {
    float: right;
  }
  .u-float-none {
    float: none;
  }


/*---------------------------------------------------------------------------
  COLUMN LAYOUTS 
  --------------------------------------------------------------------------- */          
    
  [class*="u-columns-"] {
    column-gap: $mf_gutter-default;
  } 

  .u-columns-2 {
    column-count: 2;
  }
  .u-columns-3 {
    column-count: 3;
  }       
  .u-columns-none {
    column-count: 1;
  }     

  @include media(xs) {
    .u-columns-xs-2 {
      column-count: 2;
    }
    .u-columns-xs-3 {
      column-count: 3;
    }  
    .u-columns-xs-none {
      column-count: 1;
    }       
  } 
  @include media(sm) {
    .u-columns-sm-2 {
      column-count: 2;
    }
    .u-columns-sm-3 {
      column-count: 3;
    }     
    .u-columns-sm-none {
      column-count: 1;
    }    
  }   
  @include media(md) {
    .u-columns-md-2 {
      column-count: 2;
    }
    .u-columns-md-3 {
      column-count: 3;
    }   
    .u-columns-md-none {
      column-count: 1;
    }       
  }     
  @include media(lg) {
    .u-columns-lg-2 {
      column-count: 2;
    }
    .u-columns-lg-3 {
      column-count: 3;
    }      
    .u-columns-lg-none {
      column-count: 1;
    }    
  }   
  @include media(xl) {
    .u-columns-xl-2 {
      column-count: 2;
    }
    .u-columns-xl-3 {
      column-count: 3;
    }      
    .u-columns-xl-none {
      column-count: 1;
    }    
  }   
 
  .u-columns-2 p {
    column-count: 2;
  }
  .u-columns-3 p {
    column-count: 3; 
  }       
  [class*="u-columns-"] .u-columns-p-none p { 
    column-count: 1;
  }     

  @include media(xs) {
    .u-columns-xs-p-2 p {
      column-count: 2;
    }
    .u-columns-xs-p-3 p {
      column-count: 3;
    }  
    [class*="u-columns-"] .u-columns-xs-p-none p {
      column-count: 1;
    }       
  } 
  @include media(sm) {
    .u-columns-sm-p-2 p {
      column-count: 2;
    }
    .u-columns-sm-p-3 p {
      column-count: 3;
    }     
    [class*="u-columns-"] .u-columns-sm-p-none p {
      column-count: 1;
    }    
  }   
  @include media(md) {
    .u-columns-md-p-2 p {
      column-count: 2;
    }
    .u-columns-md-p-3 p {
      column-count: 3;
    }   
    [class*="u-columns-"] .u-columns-md-p-none p {
      column-count: 1;
    }       
  }     
  @include media(lg) {
    .u-columns-lg-p-2 p {
      column-count: 2;
    }
    .u-columns-lg-p-3 p {
      column-count: 3;
    }      
    [class*="u-columns-"] .u-columns-lg-p-none p {
      column-count: 1;
    }    
  }   
  @include media(xl) {
    .u-columns-xl-p-2 p {
      column-count: 2;
    }
    .u-columns-xl-p-3 p {
      column-count: 3;
    }      
    [class*="u-columns-"] .u-columns-xl-p-none p {
      column-count: 1;
    }    
  }


  [class*="u-columns-"] p.u-columns-none {
    column-count: 1;
    max-width: 360px;
  } 