/*---------------------------------------------------------------------------
   ___ ____ ___  __  __  ___   ___  _   _ 
  |_ _/ ___/ _ \|  \/  |/ _ \ / _ \| \ | |
   | | |  | | | | |\/| | | | | | | |  \| |
   | | |__| |_| | |  | | |_| | |_| | |\  |
  |___\____\___/|_|  |_|\___/ \___/|_| \_|

  --------------------------------------------------------------------------- */

  
.icon:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  margin-right: 0.5em;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-home2:before {
  content: "\e601";
}
.icon-pencil3:before {
  content: "\e610";
}
.icon-phone-outgoing:before {
  content: "\e76e";
}
.icon-map-marker1:before {
  content: "\e77b";
}
.icon-map2:before {
  content: "\e782";
}
.icon-location:before {
  content: "\e783";
}
.icon-road-sign:before {
  content: "\e784";
}
.icon-balloon:before {
  content: "\e800";
}
.icon-enter-down2:before {
  content: "\e900";
}
.icon-cross:before {
  content: "\e92a";
}
.icon-menu:before {
  content: "\e92b";
}
.icon-exclamation:before {
  content: "\e932";
}
.icon-arrow-up:before {
  content: "\e941";
}
.icon-arrow-down:before {
  content: "\e942";
}
.icon-arrow-left:before {
  content: "\e943";
}
.icon-arrow-right:before {
  content: "\e944";
}
.icon-pencil-line:before {
  content: "\e9be";
}
.icon-pencil:before {
  content: "\e60d";
}
.icon-magic-wand:before {
  content: "\e62b";
}
.icon-star:before {
  content: "\e68d";
}
.icon-at-sign:before {
  content: "\e695";
}
.icon-envelope:before {
  content: "\e696";
}
.icon-envelope-open:before {
  content: "\e697";
}
.icon-paper-plane:before {
  content: "\e699";
}
.icon-users2:before {
  content: "\e723";
}
.icon-telephone:before {
  content: "\e76a";
}
.icon-phone-wave:before {
  content: "\e76c";
}
.icon-map-marker:before {
  content: "\e77a";
}
.icon-exclamation1:before {
  content: "\e933";
}
.icon-question1:before {
  content: "\e934";
}
.icon-check1:before {
  content: "\e935";
}
.icon-cross2:before {
  content: "\e936";
}
.icon-plus:before {
  content: "\e938";
}
.icon-minus:before {
  content: "\e937";
}
.icon-chevron-up1:before {
  content: "\e939";
}
.icon-chevron-down1:before {
  content: "\e93a";
}
.icon-chevron-left1:before {
  content: "\e93b";
}
.icon-chevron-right1:before {
  content: "\e93c";
}
.icon-star-empty3:before {
  content: "\ece3";
}
.icon-star-half:before {
  content: "\ece4";
}
.icon-star-full2:before {
  content: "\ece5";
}
.icon-new-tab2:before {
  content: "\eeca";
}
.icon-file-download:before {
  content: "\e9a5";
}
.icon-desktop:before {
  content: "\f108";
}
.icon-laptop:before {
  content: "\f109";
}
.icon-tablet:before {
  content: "\f10a";
}
.icon-mobile:before {
  content: "\f10b";
}
.icon-mobile-phone:before {
  content: "\f10b";
}
.icon-television:before {
  content: "\f26c";
}
.icon-tv:before {
  content: "\f26c";
}
.icon-columns:before {
  content: "\f0db";
}
.icon-info:before {
  content: "\f129";
}
