/*---------------------------------------------------------------------------
   ____   _    ____ _____   _        _ __   _____  _   _ _____ 
  |  _ \ / \  / ___| ____| | |      / \\ \ / / _ \| | | |_   _|
  | |_) / _ \| |  _|  _|   | |     / _ \\ V / | | | | | | | |  
  |  __/ ___ \ |_| | |___  | |___ / ___ \| || |_| | |_| | | |  
  |_| /_/   \_\____|_____| |_____/_/   \_\_| \___/ \___/  |_|  
                                                                                                                                     
  --------------------------------------------------------------------------- */


/*---------------------------------------------------------------------------
  BASIC LAYOUT 
  --------------------------------------------------------------------------- */

  .l-page {
    overflow: hidden;
  }

  .l-page__header, 
  .l-page__footer {
    position: relative;
  }     

  .l-page__container {
    position: relative;
    width: 100%;
    min-height: calc(100vh - 130px); 
  } 


/*---------------------------------------------------------------------------
  LAYOUT CONTAINERS
  --------------------------------------------------------------------------- */
  
  // center and pad containers
  .l-page__inside,
  .l-panel__inside {
    display: block;
    position: relative;
    width: 100%;
    margin:0 auto;
    padding-left: $mf_x-container-padding /  2;
    padding-right: $mf_x-container-padding / 2;   

    @include media(xs) {
      padding-left: $mf_x-container-padding;
      padding-right: $mf_x-container-padding;    
    }      
  }

  // clearfix every container row
  .l-page__row {
    @include clearfix();
  }



/*---------------------------------------------------------------------------
  Z-INDICES
  --------------------------------------------------------------------------- */


