 /*---------------------------------------------------------------------------
   COOKIE CONSENT MANAGEMENT
  --------------------------------------------------------------------------- */

  /* global layouting */

  #ccm-details .ccm-modal--header,
  #ccm-details .ccm-modal--body,
  #ccm-details .ccm-modal--footer,
  #ccm-privacy-policy .ccm-modal--footer, 
  #ccm-imprint .ccm-modal--footer,
  #ccm-control-panel .ccm-modal--footer {
    padding: 20px 20px !important;
  }


  #ccm-control-panel .ccm-modal--footer,
  #ccm-details .ccm-modal--footer,
  #ccm-privacy-policy .ccm-modal--footer, 
  #ccm-imprint .ccm-modal--footer   {
    display: block !important;
  }

  #ccm-widget .ccm-modal--footer {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }


  /* layouting on smaller screens */

  @media (max-width: 64em) {	
  	#ccm-imprint .ccm-modal-inner,
  	#ccm-privacy-policy .ccm-modal-inner,
  	#ccm-details .ccm-modal-inner,
  	#ccm-control-panel .ccm-modal-inner {
  	  max-height: 90% !important;
  	  top: auto !important;
  	  bottom: 0 !important; /* align to bottom */
  	}
  }

  @media (max-width: 64em) and (max-height: 900px) {
  	#ccm-widget .ccm-modal-inner {
  		height: 450px !important;
  	}
  } 

  @media (max-width: 575px)  {
  	#ccm-widget .ccm-modal-inner {
  		height: 650px !important;
  	}
  } 
  @media (max-width: 575px) and (max-height: 700px) {
  	#ccm-widget .ccm-modal-inner {
  		height: 90% !important;
  	}
  } 


  /* linearize modal background » non-additive*/

  .ccm-modal.show {
    background-color: rgba(0, 0, 0, 0.7) !important;
  }
  .ccm-modal.show + .ccm-modal.show,
  .ccm-modal.show + .ccm-modal + .ccm-modal.show,
  .ccm-modal.show + .ccm-modal + .ccm-modal + .ccm-modal.show {
    background: none !important; 
  }


  /* font settings */ 

  .ccm-root strong {
    font-weight: 600 !important; 
  }

  .ccm-widget--introduction {
    font-size: 14px !important;
  }

  .ccm-link-container a {
    font-size: 14px !important;
    padding: 0 5px !important;
  }

  .ccm-details--content--cookie p,
  .ccm-details--content--cookie a {
    font-size: 13px !important;
  }

  .ccm-details--content--cookie td,
  .ccm-details--content--cookie th {
    font-size: 12px !important;
  }


  /* theming global */

  .ccm-widget--logo img {
    margin-bottom: 0; /* overwrites website styles */
  }

  @media screen and (min-width: 64.0625em) {
  	.ccm-modal-inner {
  	  border-radius: 12px;
  	  overflow: hidden;
  	}
    .ccm-modal-inner {
        background: transparent !important; /* prevents bottom outlines due to border-radius */
    }
  } 


  /* theming details modal */

  .ccm-widget--text .ccm-widget--title {
    font-weight: 300 !important;
    margin-bottom: 20px !important;
  }


  /* theming details modal */

  .ccm-details--list::before {
    box-shadow: none !important;
  }
  ul.ccm-details--list--purposes>li:not(:last-child),
  .ccm-details-wrapper {
    border-bottom: 1px solid #ededed !important;
  }
  .ccm-details--list::after {
    border-right: 1px solid #ededed !important;
  }

  .ccm-details--content--cookie {
    border-bottom: 1px solid #ededed !important;
  }

  .ccm-details--content--purpose--title.ccm--h2 {
    border: none !important;
    margin-bottom: 20px !important;
  }

  .ccm-details--embedding-assets-table {
    border-radius: 5px;
    overflow: hidden;
  }

  .ccm-details--embedding-assets-table tbody tr:nth-child(odd) td {
    background: #ffffff !important;
  }

  .ccm-details--embedding-assets-table tbody tr:nth-child(even) td {
    background: #f5f5f5 !important;
  }

  .ccm-details--embedding-assets-table thead tr th {
    padding: 8px !important;
    font-weight: 300 !important;
  }

  .ccm-details--embedding-assets-table tbody tr td {
    background-color: none !important;
    padding: 8px 8px !important;
  }


  /* themning control panel */

  .ccm-root .ccm-control-panel--purpose:not(.mandatory) input[type="checkbox"].ccm-checkbox-type--switch:checked+label::before {
    background-color: #44bd32 !important;
    opacity: 0.6;
  }


  /* buttons global */

  .ccm-root .button:not(.ccm--button-primary):hover, 
  .ccm-root .button:not(.ccm--button-primary):focus, 
  .ccm-root button:not(.ccm--button-primary):hover, 
  .ccm-root button:not(.ccm--button-primary):focus {
    background: #ededed !important; 
    opacity: 0.9 !important ;
  }

  .ccm-modal--footer button,
  .ccm-widget--buttons button {
    display: inline-block !important;
    flex: none !important;
    text-shadow: none !important;
    font-weight: 300 !important;
    border: none !important;
    line-height: 46px !important;
    height: 46px !important;  
    padding: 0 20px !important;
    text-transform: none !important;
    border-radius: 10px !important;
    font-size: 14px !important;
    margin-right: 15px !important;
  }

  button.ccm--save-settings.ccm--button-primary {
    position: relative;
    float: left;
    padding: 0 56px 0 20px !important;
    text-transform: uppercase !important;
    text-decoration: none !important;
  }

  button.ccm--save-settings.ccm--button-primary:hover {
    opacity: 0.975;
  }

  button.ccm--save-settings.ccm--button-primary:after {
    content: '✓';
    background: rgba(0,0,0,0.15);
    width: 24px;
    height: 24px;
    line-height: 24px;
    border-radius: 50%;
    display: block;
    position: absolute;
    top: 12px;
    right: 14px;  
    color: #ffffff;
  }

  #ccm-imprint .ccm-modal--footer button:not(.ccm--button-primary),
  #ccm-privacy-policy .ccm-modal--footer button:not(.ccm--button-primary),
  #ccm-details .ccm-modal--footer button:not(.ccm--button-primary),
  #ccm-control-panel .ccm-modal--footer button:not(.ccm--button-primary) {
    background: rgba(255, 255, 255, 0.1) !important;
    color: #ffffff !important;
  }


  /* buttons control panel */

  #ccm-control-panel .ccm-modal--footer .ccm--save-settings.ccm--button-primary {
    float: right;
  }

  #ccm-control-panel .ccm-modal--footer button.ccm--button-primary {
    margin-right: 0 !important;
  }

  @media (max-width: 575px) { 
    #ccm-control-panel .ccm-modal--footer button {
      display: inline-block !important;
      float: left !important;
      margin: 0 5px 5px !important;
      width: calc(50% - 10px) !important;
    } 
    #ccm-control-panel .ccm-modal--footer button.ccm--button-primary {
      clear: both;
      width: calc(100% - 10px) !important;
      margin: 5px 5px 15px !important;
    }
    #ccm-control-panel .ccm-modal--footer {
      padding: 20px 10px 5px !important;
    }

  }

  /* buttons widget */

  .ccm-widget--buttons {
    display: block !important;
    border-top: 1px solid #ededed;
    padding-top: 30px;  
  }


  /* cookie declaration */

  .ccm-cookie-declaration--change-consent a {
    font-size: inherit;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    white-space: nowrap;
    user-select: none;
    display:inline-block;
    padding:12px 20px;
    margin-bottom:15px;
    background: $color-ci-primary;
    color:#fff;
    text-transform:uppercase;
  }

  .ccm-cookie-declaration--purpose--title {
    margin-top: 30px;
    padding-top: 30px;
    border-top: 1px solid #ededed;
    font-size: 32px;
  }

  .ccm-cookie-declaration--embedding--title {
    margin-top: 30px;
  }

  .ccm-cookie-declaration table {
    border-spacing: 1;
    border-collapse: collapse;
    background: white;
    overflow: hidden;
    width: 100%;
    margin: 0 auto;
    position: relative;
    border-left: 1px solid #f5f5f5;
    border-bottom: 1px solid #f5f5f5;
    margin-bottom: 30px;
  }

  .ccm-cookie-declaration table * {
    position: relative;
  }

  .ccm-cookie-declaration table td {
    border-right: 1px solid #f5f5f5;
  }

  .ccm-cookie-declaration table th {
    border-right: 1px solid #fff;
  }

  .ccm-cookie-declaration table td,
  .ccm-cookie-declaration table th {
    padding: 10px;
  }

  .ccm-cookie-declaration table thead tr {
    height: 60px;
    background: #ddd;
  }

  .ccm-cookie-declaration table tbody tr {
    height: 50px;
  }

  .ccm-cookie-declaration table tbody tr:last-child {
    border: 0;
  }

  .ccm-cookie-declaration table td,
  .ccm-cookie-declaration table th {
    text-align: left;
  }

  .ccm-cookie-declaration tbody tr:nth-child(even) {
    background-color: #f5f5f5;
  }

  .ccm-cookie-declaration tbody tr:nth-child(even) td {
    border-color: #fff;
  }


  @media screen and (max-width:992px) {

    .ccm-cookie-declaration table {
      display: block;
    }

    .ccm-cookie-declaration table > *,
    .ccm-cookie-declaration table tr,
    .ccm-cookie-declaration table td,
    .ccm-cookie-declaration table th {
      display: block;
    }

    .ccm-cookie-declaration table thead {
      display: none;
    }

    .ccm-cookie-declaration table tbody tr {
      height: auto;
      padding: 37px 0;
    }

    .ccm-cookie-declaration table tbody tr td {
      padding-left: 40% !important;
      margin-bottom: 24px;
    }

    .ccm-cookie-declaration table tbody tr td:last-child {
      margin-bottom: 0;
    }

    .ccm-cookie-declaration table tbody tr td:before {
      /*font-size: 14px;*/
      color: #999999;
      /*line-height: 1.2;*/
      /*font-weight: unset;*/
      position: absolute;
      width: 40%;
      left: 30px;
      top: 0;
    }

    .ccm-cookie-declaration table tbody tr td:nth-child(1):before {
      content: "Name";
    }

    .ccm-cookie-declaration table tbody tr td:nth-child(2):before {
      content: "Lebensdauer";
    }

    .ccm-cookie-declaration table tbody tr td:nth-child(3):before {
      content: "Beschreibung";
    }

    .ccm-cookie-declaration tbody tr {
      font-size: 14px;
    }

  }
