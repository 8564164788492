// --------------------------------------------------------------------------
//     ____ ___  __  __ ____   ___  _   _ _____ _   _ _____ 
//    / ___/ _ \|  \/  |  _ \ / _ \| \ | | ____| \ | |_   _|
//   | |  | | | | |\/| | |_) | | | |  \| |  _| |  \| | | |  
//   | |__| |_| | |  | |  __/| |_| | |\  | |___| |\  | | |  
//    \____\___/|_|  |_|_|    \___/|_| \_|_____|_| \_| |_|  
//                                                                                                                                                                                         
// -------------------------------------------------------------------------- */

/*---------------------------------------------------------------------------
  ICON BUTTON
  --------------------------------------------------------------------------- */

	.c-iconbuttons {

		.c-iconbutton__icon {
			display: inline-block;
			margin-right: 0.5em;
			
			&:last-child {
				margin-right: 0;
			}

		}

	}

	.c-iconbutton,
	.c-iconbuttons  {
		display: block;
		margin-right: 0em;
		// margin-bottom: $vr-content;

		/* item size */		

		&--small {
			font-size: 0.75rem;
		}
		&--large {
			font-size: 1.25em;
		}

		/* color schemes */


	}

	.c-iconbutton__icon {

		&--block,
		&--outlined {
			display: inline-block;
			margin-bottom: $vr-content;
		}		

		&:before {
			display: inline-block;
			position: relative;
			top: -0.1em;
			vertical-align: middle;
			font-size: 1.5em;
			margin-right: 0.5em;
			// height: 2em;
		}
		&--small:before {
			font-size: 1em;
		}
		&--large:before {
			font-size: 1.8em;
		}

		&--block:before,
		&--outlined:before {
			width: 2em;
			border-radius:50%;
			text-align: center;			
			line-height: calc(2em - 2px);
			@include transition(0.3s);
		}		

	}

