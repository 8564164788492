// --------------------------------------------------------------------------
//   __  __ _____ ____ ___    _    ___  _   _ _____ ____  ___ _____ ____  
//  |  \/  | ____|  _ \_ _|  / \  / _ \| | | | ____|  _ \|_ _| ____/ ___| 
//  | |\/| |  _| | | | | |  / _ \| | | | | | |  _| | |_) || ||  _| \___ \ 
//  | |  | | |___| |_| | | / ___ \ |_| | |_| | |___|  _ < | || |___ ___) |
//  |_|  |_|_____|____/___/_/   \_\__\_\\___/|_____|_| \_\___|_____|____/ 
//
//  https://developer.mozilla.org/de/docs/Web/CSS/Media_Queries/Using_media_queries#Pseudo-BNF_(for_those_of_you_that_like_that_kind_of_thing)
//                                                                                                                           
// --------------------------------------------------------------------------

// --------------------------------------------------------------------------
//  DEBUGGING   
// --------------------------------------------------------------------------

// @debug type-of($test);
// @debug index($test, 'ss');
// @debug nth($test, 1);
// @debug nth($test, -1);
// @debug length($test); 

// @debug map-keys($breakpoints);
// @debug length(map-keys($breakpoints));
// @debug map-get($breakpoints, 'ss');
// @debug index(map-keys($breakpoints), 'xs');
// @debug nth(map-keys($breakpoints), 1);


// --------------------------------------------------------------------------
// CONFIG
// --------------------------------------------------------------------------

  $mf-breakpoints: (
    'xs':  576px,
    'sm':  768px,
    'md':  992px,
    'lg': 1200px,
    'xl': 1440px,
  ) !default;

  $mf-mediatypes: (
    'screen', 'print', 'all', 'handheld' 
  ) !default;

  $mf-mediaquery-unit: 'em';

  $mf-unit-intervals: (
    'px': 1,
    'em': 0.01
  ) !default;

  $mf-custom-queries: (
    'landscape': '(orientation: landscape)',
    'portrait': '(orientation: portrait)',
    'retina2x': '(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi), (min-resolution: 2dppx)',
    'retina3x': '(-webkit-min-device-pixel-ratio: 3), (min-resolution: 350dpi), (min-resolution: 3dppx)'    
  ) !default;

  $mf-query-operators: (
    'w', 'h', '-'
  );


// --------------------------------------------------------------------------
// FUNCTIONS
// @depend function LIST ITEM IN STRING
// @depend function MAP KEY IN STRING
// @depend function CONVERT STRINGS TO NUMBERS
// --------------------------------------------------------------------------  

  // return unit of media query value 
  @function return-unit-from-number($number) {
    $unit: remove-operators(inspect($number));
    $numbers: '0', '1', '2', '3', '4', '5', '6', '7', '8', '9';
    @for $i from 1 through length($numbers) {
      $unit: str-replace($unit, nth($numbers, $i), '') 
    }
    @return $unit;
  }


  // remove operators from media query item
  @function remove-operators($mq-item) {
    $mq-item: inspect($mq-item);
    @for $i from 1 through length($mf-query-operators) {
      $mq-item: str-replace($mq-item, nth($mf-query-operators, $i), '');
    }
    @return $mq-item;
  }


  // check if media query items are part of breakpoint map
  @function check-mq-breakpoints($query-map) {
    $return: false !default;
    @if map-has-key($mf-breakpoints, nth($query-map, 1)) or map-has-key($mf-breakpoints, nth($query-map, 2)) {
      $return: true;
    } 
    @return $return;
  }


  // return media query items' orientation mode (width or height) 
  @function check-mq-orientation($query-orientation) {
    $return: 'width' !default;
    @return if(str-index(to-string($query-orientation), 'h'), 'height', 'width');
  }


  // return media query items' units (px or em)
  @function check-mq-size-units($query-unit) {
    $return: 'px' !default;
    @return if(str-index(to-string($query-unit), 'em'), 'em', 'px');
  }


  // transform media query strings to calculable numbers
  @function transform-query-items($query-item) {

    $return: inspect($query-item); // convert numbers to strings

    // remove operators
    @for $i from 1 through length($mf-query-operators) {
      $return: str-replace($return, nth($mf-query-operators, $i), '');
    }

    // remove size units
    @for $i from 1 through length(map-keys($mf-unit-intervals)) {
      $return: str-replace($return, nth(map-keys($mf-unit-intervals), $i), '');
    }

    // calculate final query size
    @if check-mq-size-units($query-item) == 'em' {
      @return if($mf-mediaquery-unit == 'px', to-number($return) * 16px, to-number($return) * 1em);
    } @else if check-mq-size-units($query-item) == 'px' {
      @return if($mf-mediaquery-unit == 'em', to-number($return) / 16 * 1em, to-number($return) * 1px);    
    } 

    @return $return;

  }


  // subtract unit interval from max-value
  @function subtract-from-max($query-value) {
    @return $query-value - map-get($mf-unit-intervals, $mf-mediaquery-unit);
  }


// --------------------------------------------------------------------------
// ERROR HANDLING
// --------------------------------------------------------------------------

  @mixin mq-error-handling($mq-item) {

    $default-error-message: "Invalid value in media query items!";

    $item-is-string: if(type-of($mq-item) == string, true, false);
    $item-is-list:   if(type-of($mq-item) == list, true, false);
    $item-is-map:    if(type-of($mq-item) == map, true, false);

    @if $item-is-map {
      @error "Arguments passed into media query mixin must either contain a list, a number or a string";
    } 

    @else if $item-is-string {
      
      $has-mediatype:  if(index($mf-mediatypes, $mq-item), true, false);
      $has-breakpoint: if(index(map-keys($mf-breakpoints), str-replace($mq-item, '-', '')), true, false);
      $has-custom-mq:  if(index(map-keys($mf-custom-queries), $mq-item), true, false);
      $has-operators:  if(mf-list-item-in-string($mf-query-operators, $mq-item), true, false);

      // add empty key to unit map to accont for numbers with operator
      $mf-unit-intervals: map-merge($mf-unit-intervals, ('': 1));
      $has-valid-unit: if(mf-map-key-in-string($mf-unit-intervals, return-unit-from-number(remove-operators($mq-item))), true, false);

      // if wrong media query unit is used in string
      @if not $has-mediatype and not $has-breakpoint and not $has-custom-mq {
        @if not $has-valid-unit {
          @error $default-error-message;
        } 
      } 

    } 

    @else if $item-is-list {

      // if more than two values are provided for any media query item
      @if length($mq-item) > 2 {
        @error "Do not provide more than two values (min and max) when using lists as argument!";
      } 

      @if check-mq-breakpoints($mq-item) { 

        // if one of two given breakpoints from map is not valid
        @if map-has-key($mf-breakpoints, nth($mq-item, 1)) == false or map-has-key($mf-breakpoints, nth($mq-item,2)) == false {
          @error "One of the two keys does not exist in breakpoint map. Check spelling!"; 
        }  

      } @else {

        // if width and height are mixed 
        @if check-mq-orientation(nth($mq-item, 1)) != check-mq-orientation(nth($mq-item, 2)) {
          @error "A query list must contain either width or height values.";
        }

        // if px and em are mixed
        @if return-unit-from-number(nth($mq-item, 1)) != return-unit-from-number(nth($mq-item, 2)) {
          @error $default-error-message;
        }

        // if variable types are mixed (number and string)
        @if type-of(nth($mq-item, 1)) != type-of(nth($mq-item, 2)) {
          @error "Both media query items must be of same type (number or string).";
        }

      }
  
    } 

  }


// --------------------------------------------------------------------------
// MEDIA QUERY MIXIN
// --------------------------------------------------------------------------

  @mixin media($mq-list...) {

    $mf-query-expression: ();
    $mf-mediatype:  'all' !default;  
    $mf-breakpoint: 'all' !default;
    $media-feature: 'width' !default;
    $min-max-mode:  'min' !default;  

    // for each argument passed into mixin
    @each $mq-item in $mq-list {

      // check for errors and output error messages
      @include mq-error-handling($mq-item); 
      
      // ---------------------------------------------------------------------------------------
      // 1: if media query item is a set of min and max values: "xs sm" or "768px 992px"
      // ---------------------------------------------------------------------------------------

      @if type-of($mq-item) == list {

        $min-value: ();
        $max-value: ();  

        // check if list items include breakpoints from breakpoint map
        $mq-breakpoints: check-mq-breakpoints($mq-item);

        @if $mq-breakpoints {

          // get breakpoint values from breakpoint map 
          $min-value: map-get($mf-breakpoints, nth($mq-item, 1));
          $max-value: map-get($mf-breakpoints, nth($mq-item, 2));

        } @else {

          // get individual values 
          $min-value: nth($mq-item, 1);
          $max-value: nth($mq-item, 2);

          // set media feature based on query items' orientation (width or height)
          $media-feature: check-mq-orientation($min-value);

        }

        // get final values and construct media queries
        $min-value-converted: transform-query-items($min-value);
        $max-value-converted: subtract-from-max(transform-query-items($max-value));
        $min-value-expression: 'and (min-#{$media-feature}: #{$min-value-converted})';
        $max-value-expression: 'and (max-#{$media-feature}: #{$max-value-converted})';
        $mf-query-expression: append($mf-query-expression, ($min-value-expression $max-value-expression));

      } 

      // ---------------------------------------------------------------------------------------
      // 2: if media query item is a single item: "screen" or "xs" or "768px" or "-h500px" ...
      // ---------------------------------------------------------------------------------------

      @else {

        $value: $mq-item;
        $value-converted: null; 

        // set conditions
        $has-custom-mq:  if(index(map-keys($mf-custom-queries), $mq-item), true, false);
        $has-mediatype:  if(index($mf-mediatypes, $mq-item), true, false);
        $has-breakpoint: null;
        @if type-of($mq-item) != number {
          $has-breakpoint: if(index(map-keys($mf-breakpoints), str-replace($mq-item, '-', '')), true, false);
        }

        // set media feature based on query items' orientation (width or height)
        $media-feature: check-mq-orientation($value);

        @if type-of($mq-item) == string {

          $has-operators: if(mf-list-item-in-string($mf-query-operators, $mq-item), true, false);

          // strip optional max operator (-) from media query item and set mode of media feature
          @if str-index($mq-item, '-') {
            $min-max-mode: 'max';
            $mq-item: str-replace($mq-item, '-', '');
          }

          // get and set media type 
          @if $has-mediatype {
            $mf-mediatype: #{$mq-item};
          } 

          // if breakpoint from map
          @else if $has-breakpoint {
            $value: map-get($mf-breakpoints, #{$mq-item});
          } 

          // if custom query
          @else if $has-custom-mq {
            $custom: map-get($mf-custom-queries, #{$mq-item});
            $mf-query-expression: append($mf-query-expression, 'and #{$custom}');
            // $value: $mq-item;
          }

          // if height or width operator
          @else if str-index($mq-item, 'w') or str-index($mq-item, 'h') {
            $value: $mq-item;
          } 

        } @else if type-of($mq-item) == number {

          // transform negative media query value into positive and set mode
          @if $mq-item < 0 {
            $min-max-mode: 'max';
            $mq-item: $mq-item * -1;
          }

        }

        @if not $has-custom-mq and not $has-mediatype {

          // get final values and construct media queries
          $value-converted: if($min-max-mode == 'max', subtract-from-max(transform-query-items($value)), transform-query-items($value));
          $mf-query-expression: append($mf-query-expression, 'and (#{$min-max-mode}-#{$media-feature}: #{$value-converted})');

        }

      }

    }

    // output final media query expressions
    @media #{$mf-mediatype} #{$mf-query-expression} {
      @content;
    } 

  }


// --------------------------------------------------------------------------
// TESTING
// --------------------------------------------------------------------------  

  // check media types from $mf-media-types
    // @include media(print)

  // check breakpoints from $breakpoints 
    // @include media(xs, sm, md, lg, xl) 
    // @include media(-xs, -sm, -md, -lg, -xl) 
    // @include media(xs lg) 

  // check custom mediaqueries from $mf-custom-queries
    // @include media(desktop, landscape) 
    // @include media(retina) 

  // check number-based mediaqueries
    // @include media(160, 320px, 30em)
    // @include media(-160, -320px, -30em)
    // @include media(20em 30em)

  // check query operators
    // @include media(w160, w320px, w30em, h160, h320px, h30em) 
    // @include media(-w160, -w320px, -w30em, -h160, -h320px, -h30em) 
    // @include media(w320px w920px)      

  // check for invalid mediaquery values
    // @include media(invalidvalue) 
    // @include media(h120xs) 
    // @include media(xs dg) 
    // @include media(xs 920px)     
    // @include media(720remd) 

  // go crazy
    // @include media(screen, desktop, xs, sm md, md lg, 100, 100px, 100em, w100, w100px, w100em, h100, h100px, h100em, -100, -100px, -100em, -w100, -w100px, -w100em, -h100, -h100px, -h100em) 
  
  // {  
  //   .mq-testing {
  //     font-size: 250px;
  //   }
  // }


