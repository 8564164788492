/*---------------------------------------------------------------------------
  
  NAVIGATION

  --------------------------------------------------------------------------- */

/*---------------------------------------------------------------------------
  NAVBAR
  --------------------------------------------------------------------------- */

  /* navbar toggle */

  .c-navbar__nav-toggle {
    padding: 0px 10px;
    background-color: transparent;
    cursor: pointer; 

    &:focus {
      outline: 0;
    } 
  }

  @include media(-md) {

    /* navbar structure */

    .c-navbar {
      clear: both;
    }

    .c-navbar__nav {
      margin: 0;
      padding: 10px 0 20px;
    }

    /* menu buttons */

    .o-nav__button,
    .o-nav__link {
      width: 100%;
      display: block;
      margin-right: 5px;
      margin-bottom: 5px;
      padding: 10px 12px;
      line-height: 20px;
      background: $color-lightgray;
      text-align: left;

      &:focus {
        background: $color-ci-secondary-soft;
      }
    }

    .c-navbar__submenu .o-nav__button, 
    .c-navbar__submenu .o-nav__link {
      padding: 10px 12px 10px 30px;
    }

    .c-navbar__toggle::after {
      display: inline-block;
      float: right;
      margin: 10px 10px 0 0;
      content: "";
      border-top: .3em solid;
      border-right: .3em solid transparent;
      border-bottom: 0;
      border-left: .3em solid transparent;
    }

  }  


  @include media(md) {

    /* navbar toggle */

    .c-navbar__nav-toggle {
      display:none;
    }

    /* navbar structure */

    #js-navbar {
      // overwrite bootstrap collapse styles
      display: block;
      height: auto;
      overflow: visible;
    }

    .c-navbar {
      float:left;
      // padding-left: 165px;
    }

    .c-navbar__nav {
      margin: 0;
      padding: 0;
      @include clearfix(); 
    }

    .c-navbar__item {
      position: relative;
      float: left;

      &:hover {
        // background: #e2e2e2;
        @include transition(0.3);
        
        .c-navbar__submenu {
          /* make dropdowns open on hover */
          display:block;  
        }

      }  

      .c-navbar__submenu {
        position: absolute;
        padding:10px 25px;
        left: 0px;
        padding-top: 10px;
        margin: 0;
        width: 330px;
        box-shadow: 0 3px 3px rgba(0, 0, 0, 0.05);

        @include media(xl) {
          padding-top: 15px;
        }

        @include clearfix(); 

        .c-navbar__item {
          float: none;
          display: block;
          padding:0px 15px;
          background: $color-ci-primary-dark;
        }      

        .o-nav__button,
        .o-nav__link {
          display: block;
          width: 100%;
          text-align: left;
          // line-height: 45px;
          padding:10px 0px;
          background: $color-ci-primary-dark;
          border-bottom: 1px solid rgba(255, 255, 255, 0.1);
          color: $color-white;

          @include media(xl) {
            padding: 15px 0px;
          }
        }

      }

    }

    /* menu buttons */

    .o-nav__button,
    .o-nav__link {
      position: relative;
      display:inline-block;
      color: $color-ci-secondary;
      background: transparent;
      cursor:pointer;
      padding-right: 20px;
      padding-left: 20px;

      @include media(lg) {
        padding-right: 25px;
        padding-left: 25px;
      }
      @include media(xl) {
        padding-right: 35px;
        padding-left: 35px;
      }

      .c-navbar__nav > li > &:before {
        content: '|';
        position: absolute;
        left: 0;
      }
    }

    /* dropdown menu animation */

    @keyframes fadeIn {
      0% {opacity:0;}
      100% {opacity:1;}
    }

    .c-navbar__item {

      .c-navbar__submenu {
        opacity:0;
      } 

      &:hover .c-navbar__submenu {
        opacity:1;
        display:block;
        animation:fadeIn 0.3s;
        animation-fill-mode:forwards;
      }    

    }

  }


/*---------------------------------------------------------------------------
  FOOTER LINKS
  --------------------------------------------------------------------------- */

  .footerlinks {

    ul {
      margin: 0;
      padding: 0;      
    }
    
    li {
      float: right;
      margin: 0 15px;
      font-size: 0.8rem;
    }

  }


/*---------------------------------------------------------------------------
  NAV LABELS
  --------------------------------------------------------------------------- */

  .c-navlabels {
    margin-bottom: $vr-content * 2;

    li {
      display:inline-block; 
      margin-bottom:7px;
      margin-right:5px;

      a {
        display:inline-block;
        padding:3px 10px;
        border-radius:5px;
        @include colorscheme(secondary);

        &:hover {
          @include colorscheme(secondary-light);
        }
      }      
    } 

  }

  
/*---------------------------------------------------------------------------
  SITEMAP
  --------------------------------------------------------------------------- */

      .mod_sitemap a {display:block;margin-bottom:5px;margin-right:5px;padding:10px 20px;background:rgba(0,0,0,0.05);color:#212121;border-radius:3px;font-size:14px;line-height:18px;}
    .mod_sitemap > ul.level_1 > li {margin-bottom:15px;clear: both;}
    .mod_sitemap ul.level_1 > li > a {background:rgba(0,0,0,0.25);color:#fff;}
    .mod_sitemap ul.level_2 > li.submenu > a {background:rgba(0,0,0,0.15);color:#fff;}
    .mod_sitemap ul.level_3 a {margin-left:15px;}

    @media (min-width:30em) {
        .mod_sitemap ul.level_2 li {float:left;display:inline-block;}
        .mod_sitemap ul.level_2 li a {display:inline-block;}
        .mod_sitemap ul.level_2 > li.submenu {float:none;display:block;clear:both;}
        .mod_sitemap ul.level_2 > li.submenu a {display:block;}
        .mod_sitemap ul.level_3 a {margin-left:0;}
    }

    @media (min-width:48em) {
      .mod_sitemap li a {max-width:250px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;}
        .mod_sitemap ul.level_1 > li {clear:both;margin-bottom:10px;}
        .mod_sitemap ul.level_1 > li > a {float:left;display:inline-block;width:150px;margin-right:5px;}
        .mod_sitemap ul.level_1 ul {float:left;display:inline-block;}
    }
    @media (min-width:62em) {
        .mod_sitemap > ul.level_1 > li {margin-bottom:0px;}
        .mod_sitemap ul.level_2 > li.submenu {float:left;display:block;clear:none;}
        .mod_sitemap ul.level_2 > li.submenu > a {float:left;display:inline-block;}
    }