/*---------------------------------------------------------------------------
   _______   ______   ___   ____ ____      _    ____  _   ___   __
  |_   _\ \ / /  _ \ / _ \ / ___|  _ \    / \  |  _ \| | | \ \ / /
    | |  \ V /| |_) | | | | |  _| |_) |  / _ \ | |_) | |_| |\ V / 
    | |   | | |  __/| |_| | |_| |  _ <  / ___ \|  __/|  _  | | |  
    |_|   |_| |_|    \___/ \____|_| \_\/_/   \_\_|   |_| |_| |_|  
                                                                   
  --------------------------------------------------------------------------- */
                                       

  // global responsive font sizes
  :root {
    @include fluid-type($min_width, $max_width, $min_font, $max_font);
  }

  html {
    -webkit-text-size-adjust: 100%;
    line-height: 1.4;
    color: $color-ci-primary-dark;
  }

  body {
    font-family: $font-primary;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  } 

  // set base font size for content area 
  .l-page__container {
    font-size: 1rem;
  }



/*---------------------------------------------------------------------------
  HEADLINES
  --------------------------------------------------------------------------- */        

  h1 {
    font-size: 2em;
  } 
  h2 {
    font-size: 1.8em;
    // line-height: 1.2;
  } 

  h1, h2, .font-neuton-regular {
    @include typeface(headline-primary);
    color: $color-ci-primary; 
  }

  h1 + h2, 
  h3, 
  h2 + h3, 
  h4, 
  h5, 
  h6, 
  legend {
    @include typeface(headline-secondary);
    color: $color-ci-secondary;
  }

  h1 + h2 {
    font-size: 1.4em;
    color: $color-ci-secondary;
  }

  .bg-secondary-soft h3 {
    font-size: 1.3em;
    color: $color-ci-primary; 
  }

  h1, h2, h3, h4, h5, h6 {
    margin-top:0;
    line-height: 1.2;
    font-weight: normal;
  }


  p {
    margin-top:0;
    @include typeface(copy-primary);
  }


/*---------------------------------------------------------------------------
  COPY
  --------------------------------------------------------------------------- */  

  p, ul, ol, li, i {
    font-size: 1em;
  }

  b, strong, .bold { 
    @include typeface(copy-secondary);
  }

  input, 
  select, 
  textarea {
    @include typeface(copy-primary);
  }


  .c-article {

    .ce_text:not(.u-p-regular) p {
      position: relative;
      margin-top: $vr-content * 3;

      &:before {
        content: '';
        display: block;
        position: absolute; 
        left: 0;
        top: $vr-content * -1.5;
        width: 3em;
        border-bottom: 0.5px solid $color-ci-primary;
      } 
    }

    // & .u-p-regular p:before {
    //   display: none; 
    // }

    .bg-primary p,
    .bg-secondary p  {

      &:before {
        border-bottom-color: $color-white;
      } 
    }

    h1 + h2 + p,
    h2 + h3 + p,
    .l-box + p {
      margin-top: 0;

      &:before {
        display: none;
      }
    }

    p + h2 + h3 + p,
    .l-box h2 + h3 + p {
      margin-top: $vr-content * 3;

      &:before {
        display: block;
      } 
    }

    p + h2, p + h3, p + h4, p + div {
      margin-top: $vr-content * 2;
      max-width: 600px;
    }

    h1, h2, h3, h4 {

      @include media(xs) {
        width: calc(50% - #{$mf_gutter-default}/2);
      }

    
      @include media(md lg) {
        width: 100%;
      }

    }

  }




/*---------------------------------------------------------------------------
  LINKS
  --------------------------------------------------------------------------- */ 
     
  a, 
  a:focus, 
  a:active, 
  button,
  button:focus, 
  button:active {
    text-decoration: none;
    outline:none;
    border:none; 
    background: none;
    color: $color-ci-secondary;
    
    @include transition(0.3s);

    &:hover {
      color: $color-ci-secondary;
    }    

    .bg-black &,
    .bg-darkgray & {
      color: $color-white;

      &:hover {
        color: $color-ci-primary;
      }    
    }

  }

  .ce_text a[target="_blank"]:before {
    content: "\eeca";
    position: relative;
    top: 2px;
    margin-right: 3px;
    margin-left: 5px;
  }



/*---------------------------------------------------------------------------
  TEXT HIGHLIGHT
  --------------------------------------------------------------------------- */ 

  ::selection {
    background: none repeat scroll 0 0 $color-ci-primary;
    color: $color-ci-primary-contrast;
    text-shadow: none;
  }

  ::-moz-selection {
    background: none repeat scroll 0 0 $color-ci-primary;
    color: $color-ci-primary-contrast;
    text-shadow: none;
  }