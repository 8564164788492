// --------------------------------------------------------------------------
//   _   _ _____ ___ _     ___ _____ ___ _____ ____  
//  | | | |_   _|_ _| |   |_ _|_   _|_ _| ____/ ___| 
//  | | | | | |  | || |    | |  | |  | ||  _| \___ \ 
//  | |_| | | |  | || |___ | |  | |  | || |___ ___) |
//   \___/  |_| |___|_____|___| |_| |___|_____|____/ 
//                                                    
// --------------------------------------------------------------------------

// --------------------------------------------------------------------------
//  CLEARFIXING
// --------------------------------------------------------------------------

    @mixin clearfix() {
      &::before,
      &::after {
        display: block;
        content: "";
      }
      &::after {
        clear: both;
      }
    }


// --------------------------------------------------------------------------
//  SHORTEN TEXT (with ...)
// --------------------------------------------------------------------------   

    @mixin text-shorten {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

