// --------------------------------------------------------------------------
//     ____ ___  __  __ ____   ___  _   _ _____ _   _ _____ 
//    / ___/ _ \|  \/  |  _ \ / _ \| \ | | ____| \ | |_   _|
//   | |  | | | | |\/| | |_) | | | |  \| |  _| |  \| | | |  
//   | |__| |_| | |  | |  __/| |_| | |\  | |___| |\  | | |  
//    \____\___/|_|  |_|_|    \___/|_| \_|_____|_| \_| |_|  
//                                                                                                                                                                                         
// -------------------------------------------------------------------------- */

/*---------------------------------------------------------------------------
  ICON LINK
  --------------------------------------------------------------------------- */

	.c-iconlink {
		margin-right: 0.5em;
	}
	.c-iconlinks {
		margin-right: 1.5em;
	}

	.c-iconlink,
	.c-iconlinks  {
		display: block;
		margin-bottom: $vr-content;

		/* item size */		

		&--small {
			font-size: 0.75rem;
		}
		&--large {
			font-size: 1.2em;
		}

		/* color schemes */

		&--primary .c-iconlink__link {
      color: $color-ci-primary;
		}
		&--primary .c-iconlink__link:hover {
      color: $color-ci-secondary;
		}
		&--primary .c-iconlink__link--block:before {
      @include colorscheme(primary);
		}
		&--primary .c-iconlink__link--block:hover:before {
	     @include colorscheme(secondary);
		}
		&--primary .c-iconlink__link--outlined:before {
			border-width: 1px;
			border-style: solid;
      @include colorscheme(primary-invers, border);
		}
		&--primary .c-iconlink__link--outlined:hover:before {
	    @include colorscheme(secondary-invers, border);
		}

		&--secondary .c-iconlink__link {
      color: $color-ci-secondary;
		}
		&--secondary .c-iconlink__link:hover {
      color: $color-ci-primary;
		}		
		&--secondary .c-iconlink__link--block:before {
      @include colorscheme(secondary);
		}
		&--secondary .c-iconlink__link--block:hover:before {
	     @include colorscheme(primary);
		}
		&--secondary .c-iconlink__link--outlined:before {
			border-width: 1px;
			border-style: solid;
      @include colorscheme(secondary-invers, border);
		}
		&--secondary .c-iconlink__link--outlined:hover:before {
	    @include colorscheme(primary-invers, border);
		}

	}

	.c-iconlink__link {
		margin-right: 2em;		
		margin-bottom: 1em;
		line-height: 3em;

		&.c-iconlink__knob {
			margin-right: 0em;		
		}

		&--block,
		&--outlined {
			display: inline-block;
			margin-bottom: $vr-content;
		}		

		&:before {
			display: inline-block;
			vertical-align: middle;
			font-size: 1.5em;
			margin-right: 0.25em;
			height: 2em;
			line-height: 1.8em;
		}

		&--block:before,
		&--outlined:before {
			width: 2em;
			border-radius:50%;
			text-align: center;			
			@include transition(0.3s);
			line-height: 2em;
		}		

		&--small:before {
			font-size: 1.2em;
		}
		&--large:before {
			font-size: 1.7em;
			margin-right: 0.5em;
		}
	}

