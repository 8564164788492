// --------------------------------------------------------------------------
//     ____ ___  __  __ ____   ___  _   _ _____ _   _ _____ 
//    / ___/ _ \|  \/  |  _ \ / _ \| \ | | ____| \ | |_   _|
//   | |  | | | | |\/| | |_) | | | |  \| |  _| |  \| | | |  
//   | |__| |_| | |  | |  __/| |_| | |\  | |___| |\  | | |  
//    \____\___/|_|  |_|_|    \___/|_| \_|_____|_| \_| |_|  
//                                                                                                                                                                                         
// -------------------------------------------------------------------------- */

/*---------------------------------------------------------------------------
  NEWSLIST
  --------------------------------------------------------------------------- */

	.c-newslist__headline {

		a {
			font-size: 1.25em;
			color: $color-ci-primary;
		}
	}

	.c-newslist__info {
		color: #b9b9b9;
	}

	.c-newslist__more {
		margin-top: $vr-content;
	}


/*---------------------------------------------------------------------------
  DIVERS
  --------------------------------------------------------------------------- */

  .c-newslist--short {

    p.info {
      margin-bottom: 0.5em;

      & + h3 {
        margin-top: 0;
        margin-bottom: $vr-content * 2;
        // font-size: 1.4em;
      }

    }
  }

  .c-newslayout--full {

    h1 {
      font-size: 3em;
      width: 100%;
    }

    p.info {
      margin-top: 0;
      margin-bottom: $vr-content / 2;

      &:before {
        display: none;
      }
    }
  }

  @include media(lg) {

    body.page-newsdetails .l-panel.first .l-panel__inside {
      padding-top: 4rem;
    }
  }


  
    #ratgeber-liste p.info {margin-bottom:0;font-size:0.9rem;}
    #ratgeber-liste h4 {margin-top:0;margin-bottom:30px;line-height:1;}
    #ratgeber-liste h4 a {font-size:1.125rem;line-height:1.2;}

    @media (min-width:576px) {             
      #ratgeber-liste div:nth-child(odd) {clear:both;}
    }   
    @media (min-width:992px) {             
    #ratgeber-liste p.info {font-size:1rem;}
    #ratgeber-liste h4 a {font-size:1.25rem;}
    }  
