// --------------------------------------------------------------------------
//     ____ ___  __  __ ____   ___  _   _ _____ _   _ _____ 
//    / ___/ _ \|  \/  |  _ \ / _ \| \ | | ____| \ | |_   _|
//   | |  | | | | |\/| | |_) | | | |  \| |  _| |  \| | | |  
//   | |__| |_| | |  | |  __/| |_| | |\  | |___| |\  | | |  
//    \____\___/|_|  |_|_|    \___/|_| \_|_____|_| \_| |_|  
//                                                                                                                                                                                         
// -------------------------------------------------------------------------- */

/*---------------------------------------------------------------------------
  SCROLLMAGIC PARALLAX BACKGROUNDS
  https://github.com/janpaepke/ScrollMagic                                                                                                                                
  --------------------------------------------------------------------------- */

  .c-parallax__panel {
    position: relative;
    overflow: hidden;
    z-index: 0;

    @include media(portrait) {
      height: 700px;
    }

    @include media(-md, landscape) {
      height: 400px;
    }

    @include media(md, -xl, landscape) {
      height: 500px;
    }

    @include media(xl, landscape) {
      height: 700px;
    }

  }

  .c-parallax__image {
    @include boxCover();
    overflow:hidden;

    height:100%;
    clip:rect(auto, auto, auto, auto);
  }

  .c-parallax__image-wrapper {
    overflow:hidden;
    margin-bottom: 0; 

    @include boxCover();
    
    img {
      object-fit:cover;
      height:100%;
      width:100%
    }
  }


  .c-parallax__overlay {
    @include boxCover();
    background:rgba(0,0,0,0.9);
    opacity:0.9;
  }

  // gives container full height for positioning inner content elements
  .l-parallax__inside {
    height: 100%; 
  }

  .c-parallax__content {
    @include boxCenter(onY);  
    left: 0;
    width: 100%;  
    z-index:10;

    p {
      color: $color-white;
    }
  } 


 