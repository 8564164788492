
// #row-intro .l-panel__inside {
// 	padding-top: 2em; 
// }


// .card-item h2,
// .card-item a {
// 	color: $color-ci-secondary-dark;
// }

@include media(md lg) {
	body.landing-page .c-navbar__nav .page-standorte {
		display: none;
	}

}