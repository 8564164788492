// --------------------------------------------------------------------------
//   _______   ______   ___   ____ ____      _    ____  _   ___   __
//  |_   _\ \ / /  _ \ / _ \ / ___|  _ \    / \  |  _ \| | | \ \ / /
//    | |  \ V /| |_) | | | | |  _| |_) |  / _ \ | |_) | |_| |\ V / 
//    | |   | | |  __/| |_| | |_| |  _ <  / ___ \|  __/|  _  | | |  
//    |_|   |_| |_|    \___/ \____|_| \_\/_/   \_\_|   |_| |_| |_|  
//                                                                     
// --------------------------------------------------------------------------

// --------------------------------------------------------------------------
//  WEBFONTS
//  https://google-webfonts-helper.herokuapp.com/fonts
// --------------------------------------------------------------------------  

  $file-path: '/files/theme/src/fonts/';

  @mixin font-face($font-name, $file-name, $font-weight:normal, $font-local: null, $font-woff2: true) { 

    @font-face {
        font-family: quote($font-name);
        font-style: normal;
        font-display: fallback;
        font-weight: $font-weight;
        src: url($file-path + $file-name + '.eot');

      @if $font-local != null {
        src: local('#{$font-name} #{$font-local}'), local('#{$font-name}-#{$font-local}'),
      } 

      @if $font-woff2 {
        src: url($file-path + $file-name + '.eot?#iefix') format('embedded-opentype'), 
             url($file-path + $file-name + '.woff2') format('woff2'), 
             url($file-path + $file-name + '.woff') format('woff'),
             url($file-path + $file-name + '.ttf')  format('truetype'),
             url($file-path + $file-name + '.svg##{$font-name}')  format('svg'); 
      } @else {
        src: url($file-path + $file-name + '.eot?#iefix') format('embedded-opentype'),
             url($file-path + $file-name + '.woff') format('woff'), 
             url($file-path + $file-name + '.ttf')  format('truetype'), 
             url($file-path + $file-name + '.svg##{$font-name}')  format('svg');
      }
    }
  }

  // usage
  // 
  // @include font-face( 
  //   'Open Sans', 
  //   'opensans/open-sans-v15-latin-regular'
  // );
  // 
  // @include font-face(
  //   'Icomoon', 
  //   'icomoon/fonts/icomoon',
  //   null,
  //   null,
  //   false 
  // );  


// --------------------------------------------------------------------------
//  FLUID TYPOGRAPHY
//  https://css-tricks.com/snippets/css/fluid-typography/
// --------------------------------------------------------------------------

  $min_width: 360px !default;
  $max_width: 1440px !default;
  $min_font: 14px !default;
  $max_font: 24px !default; 

  @mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {

    $u1: unit($min-vw);
    $u2: unit($max-vw);
    $u3: unit($min-font-size);
    $u4: unit($max-font-size);

    @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {

      & {

        font-size: $min-font-size;

        @media screen and (min-width: $min-vw) {
          font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
        }

        @media screen and (min-width: $max-vw) {
          font-size: $max-font-size;
        }

      }
    }
  }

  // usage
  // .element {
  //   @include fluid-type($min_width, $max_width, $min_font, $max_font);
  // }


// --------------------------------------------------------------------------
//  TYPEY - TYPEFACES
//  https://github.com/jptaranto/typey
// --------------------------------------------------------------------------
	
  // Embed font family from the $typefaces map.
  @mixin typeface($typeface) {
    $typeface-name: $typeface;
    $typeface: map-get($typefaces, $typeface);

    $font-family: false;
    $letter-spacing: false;
    $weight: false;
    $style: false;
    $case: false;

    // Assign values to variables when $typeface is a keyed map.
    @if type-of($typeface) == "map" {
      @if map-has-key($typeface, font-family) {
        $font-family: map-get($typeface, font-family);
      }
      @if map-has-key($typeface, letter-spacing) {
        $letter-spacing: map-get($typeface, letter-spacing);
      }
      @if map-has-key($typeface, weight) {
        $weight: map-get($typeface, weight);
      }
      @if map-has-key($typeface, style) {
        $style: map-get($typeface, style);
      }
      @if map-has-key($typeface, case) {
        $case: map-get($typeface, case);
      }
    }

    // Assign values to variables when $typeface is shorthand.
    @if type-of($typeface) == "list" {
      @if (list-separator($typeface) == "comma") {
        // This shorthand is just a list of fonts.
        $font-family: $typeface;
      }
      @else {
        @each $value in $typeface {
          // This is a font-family.
          @if type-of($value) == "list" {
            $font-family: $value;
          }
          // This is a letter-spacing value.
          @if type-of($value) == "number" {
            $letter-spacing: $value;
          }
          // This is a font-weight value.
          @if map-has-key($font-weight, $value) {
            $weight: $value;
          }
          // This is a case value.
          @else if type-of($value) == "string" and index($text-transform-values, $value) != null {
            $case: $value;
          }
          // This is a style value.
          @else if type-of($value) == "string" and index($font-style-values, $value) != null {
            $style: $value;
          }
        }
      }
    }

    // Output properties.
    @if $font-family {
      font-family: $font-family;
    }
    @else {
      @error "Typeface '#{$typeface-name}' does not have a font-family";
    }
    @if $letter-spacing {
      @if ($letter-spacing == 0) {
        letter-spacing: 0;
      }
      @else {
        letter-spacing: calculate-em-px($letter-spacing, $base-font-size);
      }
    }
    @if $weight {
      font-weight: weight($weight);
    }
    @if $style {
      font-style: $style;
    }
    @if $case {
      text-transform: $case;
    }
  }

// --------------------------------------------------------------------------
//  TYPEY - FONT WEIGHT
//  https://github.com/jptaranto/typey
// --------------------------------------------------------------------------

  @function weight($weight) {
    @if type-of($weight) == "string" {
      @if map-has-key($font-weights, $weight) {
        @return map-get($font-weights, $weight);
      }
      @else {
        @error "'#{$weight}' not found in $font-weight map";
      }
    }
    @else {
      @error "Weight specified for weight() is not a string";
    }
  }


// --------------------------------------------------------------------------
//  MODULAR SCALE
//  https://css-tricks.com/snippets/css/fluid-typography/
// --------------------------------------------------------------------------

