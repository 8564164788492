// --------------------------------------------------------------------------
//     ____ ___  __  __ ____   ___  _   _ _____ _   _ _____ 
//    / ___/ _ \|  \/  |  _ \ / _ \| \ | | ____| \ | |_   _|
//   | |  | | | | |\/| | |_) | | | |  \| |  _| |  \| | | |  
//   | |__| |_| | |  | |  __/| |_| | |\  | |___| |\  | | |  
//    \____\___/|_|  |_|_|    \___/|_| \_|_____|_| \_| |_|  
//                                                                                                                                                                                         
// -------------------------------------------------------------------------- */

/*---------------------------------------------------------------------------
  ICON TEXT: BASIC SETUP
  --------------------------------------------------------------------------- */

	.c-icontext {
 		margin-bottom: $vr-content;
	}

	.c-icontext__item {
		position: relative;
		margin-bottom: $vr-content;
		&--icon-top,
		&--icon-bottom {
			text-align: center;
		}
	}

	.c-icontext__icon {
		flex: 0 0 4em;

		&:before {
			display: inline-block;
			width: 2em;
			height: 2em;
			line-height: 2em;
			margin-right: 0.5em;
			border-radius:50%;
			background: $color-ci-primary;
			color: $color-white;
			text-align: center;
			font-size:1.5em;
		}		
	}

	.c-icontext__text {
		flex: 0 1 auto;
	}

	/* limit width of vertically aligned items on small/mid-sized screens */
	@include media(xs, -sm) {
		.c-icontext__item {
			&--icon-top,
			&--icon-bottom {
				width: 60%;
				margin-left: auto;
				margin-right: auto;
			}	
		}
	}
	

/*---------------------------------------------------------------------------
  ICON TEXT: MULTI-ITEM SETUP
  --------------------------------------------------------------------------- */

	/* start flexboxing on mid-sized screens */
	@include media(sm) {
		.c-icontext {
			display: flex;
			margin-left: $mf_gutter-default / -2;
			margin-right: $mf_gutter-default / -2;			 		
		}

		.c-icontext__item {
			display: flex;
			padding-left: $mf_gutter-default / 2;
			padding-right: $mf_gutter-default / 2;	

			&--icon-right {
				flex-direction: row-reverse;
			} 
			&--icon-top {
				flex-direction: column;
				text-align: center;
			}
			&--icon-bottom {
				flex-direction: column-reverse;
				text-align: center;
			}			
		}
	}

	/* for 4 items */ 
	@include media(-md) {
		.c-icontext-itemcount--4 {
			flex-wrap: wrap;
			.c-icontext__item {
				flex: 0 0 50%;
			}
		}	
	}

