/*----------------------------------------------------------------------------
   ____ ____  ___ ____  
  / ___|  _ \|_ _|  _ \ 
 | |  _| |_) || || | | |
 | |_| |  _ < | || |_| |
  \____|_| \_\___|____/ 
                                                                             
  --------------------------------------------------------------------------- */ 


/*---------------------------------------------------------------------------
  FLEXBOX
  --------------------------------------------------------------------------- */

  .flex-container {
    display:flex;

    > * {
      flex-grow: 1;
      flex-basis: 0;
      // margin-right: 20px;
    }

    @include media(-sm) {
      flex-direction: column;
      align-items: center;
      flex-wrap: wrap;

      > * {
        width: 100%;
      }      
    }
  }

