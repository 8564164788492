// --------------------------------------------------------------------------
//     ____ ___  __  __ ____   ___  _   _ _____ _   _ _____ 
//    / ___/ _ \|  \/  |  _ \ / _ \| \ | | ____| \ | |_   _|
//   | |  | | | | |\/| | |_) | | | |  \| |  _| |  \| | | |  
//   | |__| |_| | |  | |  __/| |_| | |\  | |___| |\  | | |  
//    \____\___/|_|  |_|_|    \___/|_| \_|_____|_| \_| |_|  
//                                                                                                                                                                                         
// -------------------------------------------------------------------------- */


/*---------------------------------------------------------------------------
  HERO UNIT - GLOBAL
  --------------------------------------------------------------------------- */

	.c-hero-unit {
		position: relative;
		overflow: hidden;
		display: block;
		width: 100%;
		font-size: 1rem; // scale whole component
		background: $color-black;


		// handle fullscreen mode
		&--fullscreen {

			@include media(landscape, md) {
				height: calc(100vh - 80px);

				.c-hero-unit__image {
					@include boxFullscreen();
					@include boxCenter(onXY); 

					img {
						width: 100%;
						height: 100%;
						object-fit: cover; // needs image container to be sized!
					}			

					// approximate fullscreen mode if object-fit is not supported 
					// https://caniuse.com/#feat=object-fit
					html.no-objectfit.js-landscape & img {  
						width: auto;
						height: 100vh;
					}
					html.no-objectfit.js-portrait & img {
						width: 100%;
						height: auto;
					}	
				}

			}		
			@include media(landscape, xl) {
				height: calc(100vh - 95px);
			}
			
		}

 	}

	.c-hero-unit__image {
		margin-bottom: 0;
	}


/*---------------------------------------------------------------------------
  HERO UNIT - DEFAULT THEME
  --------------------------------------------------------------------------- */

	.c-hero-unit__content {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		padding: 0.75em 0;    
		// background: rgba(6, 18, 34, 0.8);
		// background: rgba(99, 65, 51, 0.8);
		background: rgba(13, 41, 78, 0.8);
		z-index: 1;
		text-align: center;

		// responsive font-size
		@include fluid-type(20em, 100em, 1.4em, 2.5em);

		// headline type
		.c-hero-unit__headline {
			color: $color-white; 
			font-family: $font-secondary;
			font-size: 0.8em; 	
			line-height: 1;
			font-weight: weight(normal);  

			.owl-item.active & {
				opacity:0;
				position: relative;
	      animation-name: heroHeadline;
	      animation-duration: 5s;
	      animation-timing-function: linear;
	      animation-fill-mode: forwards;
	    }

		}

		.c-hero-unit__subheadline {
			font-family: $font-primary;
			font-size: 0.5em;  
			line-height: 1; 
			margin-top: 1rem;
			margin-bottom: 0;
			color: $color-white; 
			font-weight: weight(normal);

			.owl-item.active & {
				opacity:0;
				position: relative;
	      animation-name:heroSubheadline;
	      animation-duration:5s;
	      animation-timing-function:linear;
	      animation-fill-mode:forwards;
	    }			
		}

		// CTA button
		.c-hero-unit__button {
			@include clearfix();
			margin-top:3rem;
		}
		.c-hero-unit__button a {
			font-size: 0.2em; 
			line-height: 3.2em;
			float: left;
		}  
	}

	// animation

  @keyframes heroHeadline {
    0%   {opacity:0;left:-30px;}
    25%  {opacity:0;}
    50%  {opacity:1;}
    60%  {opacity:1;left:0px;}
    100% {opacity:1;left:0px;}
  }  
  @keyframes heroSubheadline {
    0%   {opacity:0;}
    50%  {opacity:0;}
    70%  {opacity:1;}
    100% {opacity:1;}
  }
  @keyframes sliderLink {
    0%   {opacity:0;}
    25%  {opacity:0;}
    75%  {opacity:0;}
    90%  {opacity:1;}
    100% {opacity:1;}
  }



/*---------------------------------------------------------------------------
  HERO UNIT - LANDING PAGES
  --------------------------------------------------------------------------- */

	.c-hero-unit-lp {
		position: relative;
		overflow: hidden;
		height: 300px;		

		@include media(sm) {
			height: 400px;
		}
		@include media(md) {
			height: 500px;
		}
		@include media(xl) {
			height: 600px;
		}

		@include media(landscape, md) {
			height: calc(100vh - 80px);
		}		
		@include media(landscape, xl) {
			height: calc(100vh - 95px);
		}

		.c-hero-unit__image {
			@include boxFullscreen();
			@include boxCenter(onXY); 

			img {
				width: 100%;
				height: 100%;
				object-fit: cover; // needs image container to be sized!
			}			

			// approximate fullscreen mode if object-fit is not supported 
			// https://caniuse.com/#feat=object-fit
			html.no-objectfit.js-landscape & img {  
				width: auto;
				height: 100vh;
			}
			html.no-objectfit.js-portrait & img {
				width: 100%;
				height: auto;
			}	
		}		




	}


	.c-hero-unit-lp__overlay { 
		background: $color-black;
		opacity:0.5;

		@include boxCover();
	}	

	.c-hero-unit-lp__content {
		position: absolute;
		bottom: 0;
		left: 0;
		top: 0;
		right: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;

		// responsive font-size
		@include fluid-type(20em, 100em, 1em, 1.75em);

		// headline type
		.c-hero-unit-lp__headline {
			margin-top: 1em;
			color: $color-white; 
			font-family: $font-secondary;
			font-size: 2.5em; 	
			font-weight: weight(normal);  
			line-height: 1;
			text-shadow: 1px 1px rgba(0,0,0,.4);

			& {
				opacity:0;
				position: relative;
	      animation-name: heroHeadline2;
	      animation-duration: 5s;
	      animation-timing-function: linear;
	      animation-fill-mode: forwards;
	    }

		}

		.c-hero-unit-lp__subheadline {
			display: inline-block;
			margin-top: 1em;
			margin-bottom: 2em;
			padding: 0.5em 1em;
			font-size: 1em;  
			font-weight: weight(normal);
			line-height: 1; 
			color: $color-ci-primary; 
			background: $color-ci-primary-soft;

			& {
				opacity:0;
				position: relative;
	      animation-name:heroSubheadline2;
	      animation-duration:5s;
	      animation-timing-function:linear;
	      animation-fill-mode:forwards;
	    }			
		}

		// CTA button
		.c-hero-unit-lp__button {
			@include clearfix();

			& {
				opacity:0;
				position: relative;
	      animation-name:heroButton;
	      animation-duration:5s;
	      animation-timing-function:linear;
	      animation-fill-mode:forwards;
	    }					
		}
	}

	// animation

  @keyframes heroHeadline2 {
    0%   {opacity:0;left:-30px;}
    25%  {opacity:0;}
    50%  {opacity:1;}
    60%  {opacity:1;left:0px;}
    100% {opacity:1;left:0px;}
  }  
  @keyframes heroSubheadline2 {
    0%   {opacity:0;}
    50%  {opacity:0;}
    70%  {opacity:1;}
    100% {opacity:1;}
  }
  @keyframes heroButton {
    0%   {opacity:0;}
    25%  {opacity:0;}
    75%  {opacity:0;}
    90%  {opacity:1;}
    100% {opacity:1;}
  }



