/*---------------------------------------------------------------------------
   ___ ____ ___  _   _ _____ ___  _   _ _____ ____  
  |_ _/ ___/ _ \| \ | |  ___/ _ \| \ | |_   _/ ___| 
   | | |  | | | |  \| | |_ | | | |  \| | | | \___ \ 
   | | |__| |_| | |\  |  _|| |_| | |\  | | |  ___) |
  |___\____\___/|_| \_|_|   \___/|_| \_| |_| |____/ 
                                                   
  --------------------------------------------------------------------------- */

/*---------------------------------------------------------------------------
  ROCKSOLID ICON PICKER
  --------------------------------------------------------------------------- */

	*[data-icon]:before,
	.-iconlist__list *[data-icon]:before {
	  content: attr(data-icon);
	  font-family: 'icomoon' !important;
	  speak: none;
	  font-style: normal;
	  font-weight: normal;
	  font-variant: normal;
	  text-transform: none;
	  line-height: 1;
	  margin-right: 0.5em;

	  /* Better Font Rendering =========== */
	  -webkit-font-smoothing: antialiased;
	  -moz-osx-font-smoothing: grayscale;
	}


/*---------------------------------------------------------------------------
  ICON ELEMENTS
  --------------------------------------------------------------------------- */

	.owl-nav button,
	.ce_text a[target="_blank"]:before {
	  font-family: 'icomoon' !important;
	  speak: none;
	  font-style: normal;
	  font-weight: normal;
	  font-variant: normal;
	  text-transform: none;
	  line-height: 1;

	  /* Better Font Rendering =========== */
	  -webkit-font-smoothing: antialiased;
	  -moz-osx-font-smoothing: grayscale;
	}


/*---------------------------------------------------------------------------
  ICON FX
  --------------------------------------------------------------------------- */

	// $icon-size	  : 60px;
	// $border-radius: 0.5;//15% = 0.15, 50% = 0.50 etc.

	// $background   : #2d2c3e;
	// $background-b : #2d2c3e;

	// // Icon Colors
	// $green        : #16A085;
	// $red          : #C82647;
	// $green-l      : #8CC63F;
	// $orange       : #EB9532;
	// $purple       : #7E3661;

	// // Text
	// $white		  : #FFFFFF;


	// .icon-fill {

	// 	&::before {
	// 		transition-duration: 0.5s;
	// 		box-shadow: inset 0 0 0 1px $color-white;
	// 	}
	// 	&:hover::before {
	// 		box-shadow: inset 0 0 0 $icon-size $color-orange;
	// 	}

	// }

	// .icon-enter {

	// 	&::after{
	// 		box-shadow: inset 0 0 0 1px $orange;
	// 	}
	// 	&::before{
	// 		border-radius: 0;
	// 		margin-left: -100%;
	// 		box-shadow: inset 0 0 0 $icon-size $orange;
	// 	}
	// 	&:hover::before{
	// 		margin-left: 0;
	// 	}

	// }

	// .icon-expand {

	// 	&::after{
	// 		box-shadow: inset 0 0 0 1px $red;
	// 	}
	// 	&::before{
	// 		background: $red;
	// 		box-shadow: inset 0 0 0 $icon-size $background;
	// 	}
	// 	&:hover::before{
	// 		box-shadow: inset 0 0 0 1px $background;
	// 	}
	// }

	// .icon-collapse {

	// 	&::before{
	// 		border-radius: 0;
	// 	}
	// 	&:hover::before{
	// 		box-shadow: 
	// 		inset 0 $icon-size/2 0 0 $green-l,
	// 		inset 0 $icon-size/-2 0 0 $green-l;
	// 	}
	// 	&::after{
	// 		box-shadow: inset 0 0 0 1px $green-l;
	// 	}
	// }

	// .icon-rotate {
	// 	box-shadow: inset 0 0 0 1px $purple;

	// 	&::after, &::before{
	// 		border: 0px solid transparent;
	// 	}
	// 	&:hover::before {
	// 		transition:
	// 			border-top-width 0.3s ease,
	// 			border-top-color 0.3s ease;
	// 		border-width: $icon-size;
	// 		border-top-color: $purple;
	// 	} 
	// 	&:hover::after {
	// 		transition:
	// 			border-left-width 0.3s ease,
	// 			border-left-color 0.3s ease;
	// 		border-width: $icon-size;
	// 		border-left-color: $purple;
	// 	}
	// 	&:hover {
	// 		transition: background 0.001s ease 0.3s;
	// 		background: $purple;
	// 	}
	// 	// i{
	// 	// 	z-index: 1;
	// 	// }
	// }

