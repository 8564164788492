// --------------------------------------------------------------------------
//   ____  _   ___   _______ _   _ __  __ 
//  |  _ \| | | \ \ / /_   _| | | |  \/  |
//  | |_) | |_| |\ V /  | | | |_| | |\/| |
//  |  _ <|  _  | | |   | | |  _  | |  | |
//  |_| \_\_| |_| |_|   |_| |_| |_|_|  |_|
//                         
// --------------------------------------------------------------------------


// --------------------------------------------------------------------------
// FLUID COMPONENT SCALING
// component sizing based on container's font-size
// --------------------------------------------------------------------------

  // activate fluid component scale
  // if activated components will use "em" units and thus be scalable
  // components are identifiable by class names starting with "c-"
  $fluid-scale: false !default;

  // include panels in fluid scale
  // if activated vertical rhythm of panels will be affected, as well
  $fluid-scale-panels: false !default;

  // scaling map
  $scaling-map: (
    xsmall:  0.6rem,
    small:   0.8rem,
    medium:  1.0rem,
    large:   1.2rem,
    xlarge:  1.5rem,
    xxlarge: 2rem,
  ) !default;


/*---------------------------------------------------------------------------
  VERTICAL RHYTHM
  --------------------------------------------------------------------------- 
  
  layout structure and terminology

  -> panels  : vertically stacked page section containers (.l-panel) 
  -> boxes   : themeable content wrappers (.l-box)    
  -> ce-wrap : structural content wrappers from CMS (.l-content, [class^='ce_'])
  -> content : content items like headlines, paragraphs, lists, figures, etc.

/*---------------------------------------------------------------------------- */

  // get unit from fluid scale setting above
  $vr-unit: if($fluid-scale, 'em', 'rem');
  $vr-unit-panels: if($fluid-scale-panels, 'em', 'rem');

  // set base bottom margin for content items 
  $vr-content: 1rem;  

  // use "em" instead of "rem" if fluid scaling option is activated
  $vr-content: if($fluid-scale, strip-unit($vr-content) * 1em, $vr-content);

  // vertical rhythm by breakpoint
  // Use keys from breakpoint map! Exception: First map key has to be "default"
  $vr-map: (
    'default' : (
      'panels'  : 2.0rem, 
      'box-in'  : 1.0rem,
      'box-out' : 2.0rem,
      'ce-wrap' : 0rem
    ),    
    'xs' : (
      'panels'  : 3.0rem, 
      'box-in'  : 1.5rem,
      'box-out' : 2.0rem,
      'ce-wrap' : 0rem
    ),
    'sm' : (
      'panels'  : 4.0rem,
      'box-in'  : 2.0rem,
      'box-out' : 2.0rem,
      'ce-wrap' : 0rem
    ),
    'md' : (
      'panels'  : 4.0rem,
      'box-in'  : 1.5rem,
      'box-out' : 2.0rem,
      'ce-wrap' : 0rem
    ),    
    'lg' : (
      'panels'  : 5.0rem,
      'box-in'  : 1.5rem,
      'box-out' : 2.0rem,
      'ce-wrap' : 0rem
    ),    
    'xl' : (
      'panels'  : 6.0rem,
      'box-in'  : 2.0rem,
      'box-out' : 3.0rem,
      'ce-wrap' : 0rem
    ),
  ) !default;


  // create CSS classes for laying out vertical rhythm across layout elements
  @mixin createVRClasses($vr-unit, $vr-panels, $vr-box-in, $vr-box-out, $vr-cewrap) {

    /* panels */ 
    .l-container__inside,
    .l-panel__inside {
      padding-top: $vr-panels;
      // bottom margin of inner elements has to be subtrated from panel's bottom padding
      padding-bottom: (strip-unit($vr-panels) - strip-unit($vr-box-out)) * 1#{$vr-unit-panels};        
    }

    /* boxes */
    .l-box {
      padding-top: $vr-box-in;
      // 1) no subtraction needed, because last content element, will have no bottom margin
      // 2) increase bottom padding for nicer delimitation
      padding-bottom: $vr-box-in * 1.3; // 1 + 2              
      margin-bottom: $vr-box-out; 
      // 3) modular scaling for horizontal rhythm
      padding-left: $vr-box-in * 1.1; // 3
      padding-right: $vr-box-in * 1.1; // 3
    }

    /* content wrappers */
    // .ce_text, 
    // .ce_hyperlink,
    // .ce_gallery, 
    // .ce_image {
    //   margin-bottom: $vr-cewrap; 
    // }
    
    /* css columns lack bottom margin on first columns */
    [class*="u-columns-"].ce_text,
    [class*="u-columns-"] > .ce_text {
      margin-bottom: $vr-box-out; 
    }

  }


  // apply vertical rhythm CSS classes to breakpoints from breakpoint map
  @each $vr-breakpoint, $vr-item in $vr-map {

    // get values from vr map
    $vr-panels:  map-deep-get($vr-map, #{$vr-breakpoint}, "panels");
    $vr-box-in:  map-deep-get($vr-map, #{$vr-breakpoint}, "box-in");
    $vr-box-out: map-deep-get($vr-map, #{$vr-breakpoint}, "box-out");
    $vr-cewrap:  map-deep-get($vr-map, #{$vr-breakpoint}, "ce-wrap");

    // switch to fluid scale if this option is activated
    $vr-panels:  if($fluid-scale-panels, $vr-panels / 1rem * 1em, $vr-panels);
    $vr-box-in:  if($fluid-scale, $vr-box-in / 1rem * 1em, $vr-box-in);
    $vr-box-out: if($fluid-scale, $vr-box-out / 1rem * 1em, $vr-box-out);
    $vr-cewrap:  if($fluid-scale, $vr-cewrap / 1rem * 1em, $vr-cewrap);

    // create CSS classes below first breakpoint
    @if $vr-breakpoint == 'default' {
      @include createVRClasses($vr-unit, $vr-panels, $vr-box-in, $vr-box-out, $vr-cewrap)
    } 

    // create CSS classes for each breakpoint
    @else {
      @include media(#{$vr-breakpoint}) {
        @include createVRClasses($vr-unit, $vr-panels, $vr-box-in, $vr-box-out, $vr-cewrap)
      }
    }

  }


// --------------------------------------------------------------------------
//   _   _ _____ ___ _     ___ _____ ___ _____ ____  
//  | | | |_   _|_ _| |   |_ _|_   _|_ _| ____/ ___| 
//  | | | | | |  | || |    | |  | |  | ||  _| \___ \ 
//  | |_| | | |  | || |___ | |  | |  | || |___ ___) |
//   \___/  |_| |___|_____|___| |_| |___|_____|____/ 
//                                                    
//  Responsive utility classes for vertical rhythm and scaling
//
// --------------------------------------------------------------------------


/*---------------------------------------------------------------------------
  RESPONSIVE SPACING
  --------------------------------------------------------------------------- */

  // create equal height utility classes for panel content
  @mixin createSpacingClasses($bpName, $vr-box-in, $vr-box-out) {

    $bpName: if($bpName, '-#{$bpName}', '');

    .l-padding#{$bpName} {
      padding-bottom: $vr-content;
    }    
    .l-margin#{$bpName} {
      margin-bottom: $vr-content;
    } 
    .l-padding#{$bpName}-2vr {
      padding-bottom: $vr-content * 2;
    }    
    .l-margin#{$bpName}-2vr {
      margin-bottom: $vr-content * 2;
    }    
    .l-padding#{$bpName}-3vr {
      padding-bottom: $vr-content * 3;
    }    
    .l-margin#{$bpName}-3vr {
      margin-bottom: $vr-content * 3;
    }        
    .l-padding#{$bpName}-none {
      padding-bottom: 0;
    } 
    .l-margin#{$bpName}-none {
      margin-bottom: 0;
    } 

  }

  // below first breakpoint
  @include createSpacingClasses(false, map-deep-get($vr-map, 'default', 'box-in'), map-deep-get($vr-map, 'default', 'box-out'));

  // for each breakpoint from breakpoint map
  $vr-box-in: 0;  // declare outside @each to account for missing breakpoint keys in vr-map
  $vr-box-out: 0; // declare outside @each to account for missing breakpoint keys in vr-map

  @each $bpName, $bpValue in $mf-breakpoints {

    // assign new value if current breakpoint is key in vr-map
    // (if not variables will still hold previous value)
    @if map-has-key($vr-map, #{$bpName}) {
      $vr-box-in: map-deep-get($vr-map, $bpName, 'box-in');
      $vr-box-out: map-deep-get($vr-map, $bpName, 'box-out');
    }     

    @include media('#{$bpName}') {
      @include createSpacingClasses($bpName, $vr-box-in, $vr-box-out);
    }  

  }


/*---------------------------------------------------------------------------
  EQUAL HEIGHT BOXES
  --------------------------------------------------------------------------- */

  // create equal height utility classes for panel content
  @mixin createEHClasses($vr-itemcount, $bpName, $vr-panels) {

    $classesString: "";

    $firstOccurence: index(map-keys($vr-map), $bpName); // item index in vr-map as starting point 
    $numberOfPasses: length(map-keys($mf-breakpoints)) + 1; // "+ 1" accounts for "default" item in vr-map

    @for $i from 2 through $vr-itemcount {
      $comma: if($i == $firstOccurence - $numberOfPasses, " ", ", ");
      $vrBpName: nth(map-keys($vr-map), $i);
      $classesString: $classesString + ".l-align-#{$vrBpName}-stretch .l-panel__inside#{$comma}";
    }

    #{$classesString} {
      padding-top: $vr-panels;
      // bottom padding needs to equal top padding, because
      // bottom margins will have to be reset in order not to break layout
      padding-bottom: $vr-panels;

      [class*="ce_"], 
      .l-box {
        height: 100%;
        margin-bottom: 0;

        // [class^="ce_"], 
        // .l-box {
        //   height: auto;
        // }
      }

    }

  }

  // for each breakpoint from breakpoint map
  $vr-panels: 0; // declare outside @each to account for missing breakpoint keys in vr-map
  $vr-itemcount: 1; // start after default item in vr-map

  @each $bpName, $bpValue in $mf-breakpoints {
    $vr-itemcount: $vr-itemcount + 1;

    @include media('#{$bpName}') {
      @if map-has-key($vr-map, #{$bpName}) {
        $vr-panels: map-deep-get($vr-map, #{$bpName}, 'panels');
      } 
      @include createEHClasses($vr-itemcount, $bpName, $vr-panels);
    }  

  }


/*---------------------------------------------------------------------------
  CONTENT SCALING
  --------------------------------------------------------------------------- */

  // create content scaling utility classes 
  @mixin createScalingClasses($bpName) {

    $bpName: if($bpName, '-#{$bpName}', '');

    @each $scale-item, $scale-value in $scaling-map {
      .s-scale#{$bpName}-#{$scale-item} {
        font-size: $scale-value;
      } 
    }    

  }

  // below first breakpoint
  @include createScalingClasses(false);

  // for each breakpoint from breakpoint map
  @each $bpName, $bpValue in $mf-breakpoints {
    @include media('#{$bpName}') {
      @include createScalingClasses($bpName);
    }  
  }
