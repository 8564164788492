// --------------------------------------------------------------------------
//     ____ ___  __  __ ____   ___  _   _ _____ _   _ _____ 
//    / ___/ _ \|  \/  |  _ \ / _ \| \ | | ____| \ | |_   _|
//   | |  | | | | |\/| | |_) | | | |  \| |  _| |  \| | | |  
//   | |__| |_| | |  | |  __/| |_| | |\  | |___| |\  | | |  
//    \____\___/|_|  |_|_|    \___/|_| \_|_____|_| \_| |_|  
//                                                                                                                                                                                         
// -------------------------------------------------------------------------- */

/*---------------------------------------------------------------------------
  TEAM BADGE
  --------------------------------------------------------------------------- */

  .c-badge {  
  	position: relative;

	  @include media (-sm) {
    	max-width: 300px;
	  }

	  .c-badge__caption {
	  	display: block;
	  	position: absolute;
	  	z-index: 2;
	  	bottom: 1em;
	  	width: 100%;
	  	padding: 0.5em 1em;
			background: rgba(13, 41, 78, 0.8);
	  	border-bottom:5px solid $color-ci-primary;
	  	color: $color-white;

		  @include media (md) {
	    	bottom: 1.5em;
		  }	  	
	  }

		.c-badge__headline {
			display: block;
			font-family: $font-secondary;
			font-size: 1.3rem;
			margin-bottom: 0;
		}

		.c-badge__text {
			display: block;
			font-size: 1.1rem;
			font-family: $font-primary;
		}

  }