 /*---------------------------------------------------------------------------
      ____   _    _   _ _____ _     ____  
     |  _ \ / \  | \ | | ____| |   / ___| 
     | |_) / _ \ |  \| |  _| | |   \___ \ 
     |  __/ ___ \| |\  | |___| |___ ___) |
     |_| /_/   \_\_| \_|_____|_____|____/ 
                                                                                                                                                                           
  --------------------------------------------------------------------------- */

    .l-panel {

        position: relative;
        display: block;
        width: 100%;

        &--fullscreen {
            height: 100vh;
        }

    }


 /*---------------------------------------------------------------------------
      ____   _____  _______ ____  
     | __ ) / _ \ \/ / ____/ ___| 
     |  _ \| | | \  /|  _| \___ \ 
     | |_) | |_| /  \| |___ ___) |
     |____/ \___/_/\_\_____|____/ 
                              
  --------------------------------------------------------------------------- */
     


  
 /*---------------------------------------------------------------------------
      ____  _   ___   _______ _   _ __  __ 
     |  _ \| | | \ \ / /_   _| | | |  \/  |
     | |_) | |_| |\ V /  | | | |_| | |\/| |
     |  _ <|  _  | | |   | | |  _  | |  | |
     |_| \_\_| |_| |_|   |_| |_| |_|_|  |_|
                                       
  --------------------------------------------------------------------------- */

  
  /* headlines */

  h1 {
    margin-bottom: $vr-content / 1.75;
  }
  h1 + p,
  h1 + ul,
  h1 + ol {
    margin-top: $vr-content;
  }

  h1 + h2 {
    // margin-top: -$vr-content/2;
  }
  
  .hl-block {
    display: block;
  }


  h2, h3, h4, h5 {
    margin-bottom: $vr-content; 
  }
  ul + h2, ul + h3, ul + h4, ul + h5, ul + h6,
  ol + h2, ol + h3, ol + h4, ol + h5, ol + h6  { 
    margin-top: $vr-content * 2; 
  }
  p + h2, p + h3, p + h4, p + h5, p + h6  { 
    margin-top: $vr-content;
  }


  /* paragraphs */

  p {
    margin-bottom: $vr-content; 
  }
  p + p,
  p + div {
    margin-top: $vr-content;
  }

  /* lists */

  .ce_text > ul, 
  .ce_text > ol {
    margin-bottom: $vr-content * 2; 

    li {
      margin-bottom: $vr-content; 
      
      &:last-child {
        margin-bottom: 0; 
      }      
    }

    ul, ol {
      margin-top: $vr-content;
    }
  }


  p + ul, p + ol,
  h1 + ul, h2 + ul, h3 + ul, h4 + ul, h5 + ul, h6 + ul,
  h1 + ol, h2 + ol, h3 + ol, h4 + ol, h5 + ol, h6 + ol {
    margin-top: $vr-content;    
  }            

  /* links */

  // .ce_text a,
  // .ce_text button {
  //   margin-top: $vr-content;
  // }

  p + a, p + button,
  ul + a, ul + button,
  ol + a, ol + button {
    margin-top: $vr-content * 2;
  }    

  .ce_text ul + p > a,
  .ce_text ol + p > a  {
    margin-top: 0;
  }

  p + p .c-button,
  p + div .c-button {
    margin-top: $vr-content * 1;
  }

  // .c-button + .c-button {
  //   margin-top: $vr-content * 0.5;
  // }


  /* images */

  figure { 
    margin-bottom: $vr-content * 2;
  }
  
  figcaption{ 
    margin-top: $vr-content * 2;
  }

  p + figure,
  ul + figure, ol + figure,
  .c-textimage__image--float_below figure { 
    margin-top: $vr-content;
  }
    
  .l-box > *:last-child,
  .l-box > *:last-child > *:last-child {
    margin-bottom: 0;
  }

 .l-box [class^=col-]:last-child > [class^=ce_],
 .l-box [class^=col-]:last-child > [class^=ce_]:last-child > *:last-child {
    margin-bottom: 0;
 }
