// --------------------------------------------------------------------------
//    ____ ___  _   _ _____ ___ ____ 
//   / ___/ _ \| \ | |  ___|_ _/ ___|
//  | |  | | | |  \| | |_   | | |  _ 
//  | |__| |_| | |\  |  _|  | | |_| |
//   \____\___/|_| \_|_|   |___\____|
//                                   
// --------------------------------------------------------------------------


// --------------------------------------------------------------------------
// MEDIAQUERIES
// mixins/_mediaqueries.scss
// --------------------------------------------------------------------------

	// breakpoint map
  $mf_breakpoints: (
    'xs':  576px,
    'sm':  768px,
    'md':  992px,
    'lg': 1200px,
    'xl': 1440px
  );

	// allowed media types for media queries
  $mf_mediatypes: (
    'screen', 
    'print', 
    'all' 
  );

	// unit to be output in media queries
  $mf_mediaquery-unit: 'em';

	// intervals subtracted from max values
  $mf_unit-intervals: (
    'px': 1,
    'em': 0.01
  );

	// custom media queries
  $mf_custom-queries: (
    'retina':  '(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi), (min-resolution: 2dppx)',
    'desktop': '(min-width: 992px) and (max-width: 1199px)',
    'handheld': 'handheld',
    'landscape': '(orientation: landscape)',
    'portrait': '(orientation: portrait)',
    'retina2x': '(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi), (min-resolution: 2dppx)',
    'retina3x': '(-webkit-min-device-pixel-ratio: 3), (min-resolution: 350dpi), (min-resolution: 3dppx)'    
  );


// --------------------------------------------------------------------------
// PAGE CONTAINER
// mixins/_grid.scss
// --------------------------------------------------------------------------

  // define breakpoint at which container has fixed width  
  $mf_fluid-container-break: 'md';

// --------------------------------------------------------------------------
// HORIZONTAL CONTAINER PADDING 
// --------------------------------------------------------------------------

  $mf_x-container-padding: 2rem;


// --------------------------------------------------------------------------
// GRID
// mixins/_grid.scss
// --------------------------------------------------------------------------

  // Grid columns 
  $mf_grid-cols: 12;

  // Grid gutter
  $mf_gutter-default: 2rem;
  $mf_gutter-small:   1rem;
  $mf_gutter-medium:  $mf_gutter-default;
  $mf_gutter-large:   4rem;
  
    
// --------------------------------------------------------------------------
// FLUID COMPONENT SCALING
// component sizing based on container's font-size
// --------------------------------------------------------------------------

  // activate fluid component scale
  // if activated components will use "em" units and thus be scalable
  // components are identifiable by class names starting with "c-"
  $fluid-scale: false;

  // include panels in fluid scale
  // if activated vertical rhythm of panels will be affected, as well
  $fluid-scale-panels: false;

  // scaling map
  $scaling-map: (
    small:   0.9rem,
    medium:  1.0rem,
    large:   1.2rem,
  );


//  ---------------------------------------------------------------------------
//  ASPECT RATIOS
//  mixins/_layout.scss
//  --------------------------------------------------------------------------- */

  // aspect ratios for layouting to be used in mixin boxRatio()
  $mf-aspect-ratios: (
    golden:     (width: 1.61803398875, height: 1),
    square:     (width: 1, height: 1),
    tv:         (width: 4, height: 3),
    widescreen: (width: 5, height: 3),
    hdvideo:    (width: 16, height: 9),
  );

    
// --------------------------------------------------------------------------
// ...
// --------------------------------------------------------------------------




//  ---------------------------------------------------------------------------
//  __     ___    ____  ___    _    ____  _     _____ ____  
//  \ \   / / \  |  _ \|_ _|  / \  | __ )| |   | ____/ ___| 
//   \ \ / / _ \ | |_) || |  / _ \ |  _ \| |   |  _| \___ \ 
//    \ V / ___ \|  _ < | | / ___ \| |_) | |___| |___ ___) |
//     \_/_/   \_\_| \_\___/_/   \_\____/|_____|_____|____/ 
//     
//  ---------------------------------------------------------------------------                                                       


/*---------------------------------------------------------------------------
  COLORS
  --------------------------------------------------------------------------- */

  $color-white: #FFFFFF;
  $color-black: #1C1F21;

  $color-gray: #434343;  
  $color-lightgray: #f2f2f2;
  $color-darkgray: #8e8e8e;

  $color-ci-primary: #0D294E;
  $color-ci-primary-contrast: $color-white;
  $color-ci-primary-light: lighten($color-ci-primary, 5%);
  $color-ci-primary-dark: darken($color-ci-primary, 10%);
  $color-ci-primary-soft: lighten($color-ci-primary, 80%);
  $color-ci-primary-deep: darken($color-ci-primary, 30%);

  $color-ci-secondary: #855744; 
  $color-ci-secondary-contrast: $color-white;
  $color-ci-secondary-light: #E8DFD9;  
  $color-ci-secondary-dark: darken($color-ci-secondary, 10%);
  $color-ci-secondary-soft: lighten($color-ci-secondary, 58%);
  $color-ci-secondary-deep: darken($color-ci-secondary, 20%);

  $color-ci-text-highlight: $color-ci-primary;
  $color-ci-dark: color-ci-primary-deep;




/*---------------------------------------------------------------------------
  FONTS
  --------------------------------------------------------------------------- */

	// global responsive typography  
	$min_width: 320px;
  $max_width: 1200px;
  $min_font: 14px; 
  $max_font: 16px;

	// font stacks
	$font-primary: 'colaborate-thin', Arial, sans-serif;
	$font-secondary: 'neuton-regular', helvetica, sans-serif;
  $font-tertiary: 'colaborate-regular', Arial, sans-serif;

	// font weight map for weight function
	$font-weights: (
	  normal: 400,
	  bold: 600,
	  bolder: 700,
	  extrabold: 800
	);	
  
  // typeface map
  $typefaces: (
    headline-primary: (
      font-family: $font-secondary,
      weight: normal,
      style: normal,
      case: none,
    ),   
    headline-secondary: (
      font-family: $font-primary,
      weight: normal,
      style: normal,
      case: none,
    ),
    copy-primary: (
      font-family: $font-primary,
      weight: normal,
      style: normal,
      case: none,
    ),
    copy-secondary: (
      font-family: $font-tertiary,
      weight: normal,
      style: normal,
      case: none,
    ),    
  );
